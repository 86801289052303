import { Link } from "react-router-dom";
import {
  A,
  Accordion,
  AccordionPanelBox,
  ChevronIcon,
  Container,
  Copyright,
  CopyrightSection,
  Disclaimer,
  FooterBar,
  HorizontalBar,
  IconWrapper,
  Logo,
  SecondaryFooter,
  Strapline,
  ToggleButton,
  Wrapper,
} from "./styles";

const Header = () => {
  const year = new Date().getFullYear();

  return (
    <Wrapper>
      <Disclaimer>
        <p>
          The prices are guides only for the date shown and are rounded. The
          price used for a transaction will depend on the date and the time of
          your request. Performance is net of the Annual Management Charge.
          Performance figures reflect Price Date. Performance figures are
          calculated monthly by FE fundinfo.
        </p>

        <p>
          The information on the investment proposal tool is designed for
          financial advisers. It's not suitable for anyone else. If you're not a
          financial adviser, please go to{" "}
          <A
            to="https://www.standardlife.ie"
            target="_blank"
            rel="noreferrer"
            title="Visit Standard Life Ireland site"
          >
            standardlife.ie
          </A>{" "}
          for information about the products and services we offer.
        </p>
      </Disclaimer>

      <FooterBar />

      <Container>
        <Link
          to="https://www.standardlife.ie"
          target="_blank"
          rel="noreferrer"
          title="Visit Standard Life Ireland site"
        >
          <Logo role="presentation" />
        </Link>

        <Strapline>Part of Phoenix Group</Strapline>

        <HorizontalBar />
      </Container>

      <SecondaryFooter>
        <Container>
          <Accordion>
            <ToggleButton>
              <IconWrapper>
                <ChevronIcon />
              </IconWrapper>
              Brand website and provider details
            </ToggleButton>

            <AccordionPanelBox>
              <p>
                This site features Irish products provided for residents of the
                Republic of Ireland by Standard Life International dac.
              </p>

              <p>
                Standard Life International dac is regulated by the Central Bank
                of Ireland. Standard Life International dac is a designated
                activity company limited by shares and registered in Dublin,
                Ireland (408507) at 90 St Stephen's Green, Dublin D02 F653.
              </p>

              <p>&copy;&nbsp;{year} Standard Life. All rights reserved.</p>
            </AccordionPanelBox>
          </Accordion>

          <CopyrightSection>
            <Copyright>
              &copy;&nbsp;{year} Standard Life. All rights reserved.
            </Copyright>
          </CopyrightSection>
        </Container>
      </SecondaryFooter>
    </Wrapper>
  );
};

export default Header;
