import styled from "styled-components";
import { Link } from "react-router-dom";
import uiLogo from "../Brand/Logo";
import uiFooterBar from "./FoorterBar";
import { breakpointUp } from "helpers/breakpoints";
import uiChevronIcon from "components/icons/ChevronIcon";

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: 30px;
  min-height: 400px;
  padding-top: 40px;

  ${breakpointUp.md`
    margin-top: 70px;
  `}
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
`;

export const FooterBar = styled(uiFooterBar)`
  display: block;
  width: 100%;
  margin-top: -40px;
`;

export const Logo = styled(uiLogo)`
  width: 200px;
  height: auto;
  margin: 0 16px 15px 0;
  color: ${({ theme }) => theme.colors.primaryBlue};

  /* ${breakpointUp.md`
    width: 200px;
  `} */
`;

export const Strapline = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const HorizontalBar = styled.div`
  border-top: 3px solid #c1c1c1;
  height: 50px;
`;

export const SecondaryFooter = styled.div`
  background-color: #f5f6f7;

  ${Container} {
    padding: 10px 10px 0 10px;

    ${breakpointUp.md`
        display: flex;
      `}
  }
`;

export const Accordion = styled.div`
  padding: 0 16px;
`;

export const ToggleButton = styled.button.attrs({ type: "button" })`
  background-color: transparent;
  color ${({ theme }) => theme.colors.primaryBlue};
`;

export const IconWrapper = styled.span`
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.colors.secondaryBlue};
  border-radius: 7px;
  margin-right: 10px;
`;

export const ChevronIcon = styled(uiChevronIcon)`
  position: relative;
  top: -2px;
  width: 8px;
  color: ${({ theme }) => theme.colors.white};
`;

export const AccordionPanelBox = styled.div`
  border-left: 1px solid #fb0;
  margin: 0 0 0 8px;
  padding: 2px 0 0 16px;
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const CopyrightSection = styled.div`
  white-space: nowrap;
  margin-right: 100px;
  padding-left: 16px;
`;

export const Copyright = styled.p`
  color ${({ theme }) => theme.colors.primaryBlue};
`;

export const Disclaimer = styled.div`
  display: block;
  max-width: 50%;
  margin: 0 auto;
  border-left: 4px solid ${({ theme }) => theme.colors.primaryBlue};
  padding-left: 20px;
  color: ${({ theme }) => theme.colors.primaryText};
  margin-bottom: 40px;

  ${breakpointUp.sm`
    margin-bottom: 0;
  `}
`;

export const A = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryBlue};
    text-decoration: none;
  }
`;
