import { configureStore } from "@reduxjs/toolkit";

import investmentDetailsSlice from "./features/investmentDetails/investmentDetailsSlice";
import investmentFundsReducer from "./features/investmentFunds/investmentFundsSlice";
import investmentAmountSlice from "./features/investmentAmount/investmentAmountSlice";
import chargesSlice from "./features/charges/chargesSlice";
import personaliseReportSlice from "./features/personaliseReport/personaliseReportSlice";

const store = configureStore({
  reducer: {
    investmentDetails: investmentDetailsSlice,
    investmentFunds: investmentFundsReducer,
    investmentAmount: investmentAmountSlice,
    charges: chargesSlice,
    personaliseReport: personaliseReportSlice,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
