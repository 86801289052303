import { useDispatch, useSelector } from "app/hooks";
import { personaliseReportActions } from "../../../app/features/personaliseReport/personaliseReportSlice";
import { logAnalyticsEvent } from "services/analytics";

export const useSectionListItem = (
  key: string,
  label: string
): { value: boolean; handleOnChange: Function } => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.personaliseReport);

  const handleOnChange = (value: boolean) => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 3",
      label: `${label} - ${value ? "No to Yes" : "Yes to No"}`,
    });
    dispatch(
      personaliseReportActions.updateReportSection({ section: key, value })
    );
  };

  return {
    value: !!(state as any)[key],
    handleOnChange,
  };
};
