import styled from "styled-components";
import uiCheckbox from "components/ui/Checkbox/Checkbox";
import { H3, H4, P } from "components/ui/Typography";
import { breakpointUp } from "helpers/breakpoints";
import NumericInputGroup from "../forms/NumericInputGroup";

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 8px;
  padding: 0 32px 32px 32px;
  margin-bottom: 16px;
`;

export const CardTitle = styled(H3)`
  margin: 0 -32px;
  padding: 10px 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  font-size: 20px;
`;

export const CardContent = styled.div`
  width: 100%;

  ${breakpointUp.lg`
        max-width: 580px;
    `}
`;

export const FieldWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
`;

export const InlineLabel = styled.label`
  display: flex;
  flex: 1;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding: 3px 10px;
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: ${({ theme }) => theme.colors.paleGrey};
  border-radius: 5px;
  min-height: 44px;

  small {
    display: block;
    font-size: 14px;
    line-height: 14px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.paleText};
    margin-top: 4px;
  }
`;

export const AMCInput = styled(NumericInputGroup)`
  display: flex;
  flex: 0 0 130px;
  height: 44px;
  margin-left: 8px;
`;

export const AMCRebateInput = styled(NumericInputGroup)`
  display: flex;
  flex: 0 0 130px;
  height: 44px;
  margin-left: 8px;
`;

export const FBRCInput = styled(NumericInputGroup)`
  display: flex;
  flex: 0 0 130px;
  height: 44px;
  margin-left: 8px;
`;

export const Summary = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  padding: 0 10px;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.bodyText};
  background-color: ${({ theme }) => theme.colors.paleGrey};
  border-radius: 5px;

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }

  &.error {
    color: ${({ theme }) => theme.colors.error};

    small {
      color: ${({ theme }) => theme.colors.error};
    }
  }
`;

export const Label = styled.p`
  margin: 0;
  flex: 1;

  small {
    display: block;
    font-size: 14px;
    line-height: 1.3;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.paleText};
  }
`;

export const Total = styled.p`
  margin: 0;
`;

export const Checkbox = styled(uiCheckbox)``;

export const AdditionalExpensesSection = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`;

export const AdditionalExpensesHeading = styled(H4)`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
`;

export const AdditionalExpensesDescription = styled(P)`
  font-size: 14px;
  line-height: 1.3;
`;

export const AdditionalExpenseInput = styled(NumericInputGroup)`
  display: flex;
  flex: 0 0 130px;
  height: 44px;
  margin-left: 8px;
`;

export const Symbol = styled.div`
  font-size: 26px;
  font-weight: 300;
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.paleText};
  width: 14px;
  text-align: center;
`;

export const ErrorMessage = styled(P)`
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.error};
`;
