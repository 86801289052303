import styled, { keyframes } from 'styled-components';

const rotateSpinner = keyframes`
    to {
		transform: rotate(360deg);
	}
`;

export const Wrapper = styled.div`
    animation-name: ${rotateSpinner};
    animation-duration: 0.8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    width: 50px;
    height: 50px;
`;