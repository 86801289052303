import Card from "../ui/Card";
import SectionListItem from "./SectionListItem/SectionListItem";
import useReportSectionsCard from "./hooks";
import {
  IncludeHeaderCol,
  IntroMessage,
  SectionHeaderCol,
  SectionList,
  SectionListHeader,
} from "./styles";

const ReportSectionsCard = ({ props }: any) => {
  const { handleExampleReportClick } = useReportSectionsCard();

  return (
    <Card title="Sections" {...props}>
      <IntroMessage>
        Include the sections you want, ensuring that your clients receive
        relevant, focused information tailored to their needs. If you're unsure
        about the arrangement or content of each section, simply{" "}
        <a
          href="/IPTSampleReport190624.pdf"
          rel="noreferrer"
          target="_blank"
          onClick={handleExampleReportClick}
        >
          download our example report
        </a>
        .
      </IntroMessage>

      <SectionList>
        <SectionListHeader>
          <SectionHeaderCol>Section</SectionHeaderCol>

          <IncludeHeaderCol>Include</IncludeHeaderCol>
        </SectionListHeader>

        <SectionListItem
          label="Fund descriptions"
          sectionKey="includeFundDescriptionsSection"
        />

        <SectionListItem
          label="High level asset allocation"
          sectionKey="includeHighLevelAssetAllocationSection"
        />

        <SectionListItem
          label="Regional equity breakdown"
          sectionKey="includeRegionalEquityBreakdownSection"
        />

        <SectionListItem
          label="Discrete portfolio performance"
          sectionKey="includeDiscretePortfolioPerformanceSection"
        />

        <SectionListItem
          label="Individual fund performance"
          sectionKey="includeIndividualFundPerformanceSection"
        />

        <SectionListItem
          label="Risk profile"
          sectionKey="includeRiskProfileSection"
        />

        <SectionListItem
          label="Terms and charges"
          sectionKey="includeTermsAndChargesSection"
        />
      </SectionList>
    </Card>
  );
};
export default ReportSectionsCard;
