import {
  FundAllocationHeader,
  FundAMCHeader,
  FundNameHeader,
  Wrapper,
} from "./styles";

const FundsHeader = () => (
  <Wrapper>
    <FundNameHeader>Funds</FundNameHeader>
    <FundAMCHeader>AMC</FundAMCHeader>
    <FundAllocationHeader>Allocation</FundAllocationHeader>
  </Wrapper>
);

export default FundsHeader;
