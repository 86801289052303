import { FlexContainer, SectionIndicator, SectionNumber, Title, Wrapper } from "./styles";

const ProgressStep = ({title, currentStep, totalSteps, ...otherProps}) => (
    <Wrapper {...otherProps}>

        <SectionNumber>Section {currentStep} of {totalSteps}</SectionNumber>

        <Title>{title}</Title>

        <FlexContainer>
            {[...Array(totalSteps)].map((_, index) => {
                let state = 'next';
                if(index + 1 < currentStep) {
                    state = 'previous';
                }
                else if(index + 1 === currentStep) {
                    state = 'current';
                }
                return <SectionIndicator key={index} state={state} />
            })}
        </FlexContainer>

    </Wrapper>
)

export default ProgressStep;