import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { GetUploadLogoUrlQuery } from "API";
import { getUploadLogoUrl } from "graphql/queries";

export const uploadImage = async (
  imageDataURL: string,
  logoImageId: string
): Promise<void> => {
  const fileBlob = await fetch(imageDataURL).then((res) => res.blob());
  const input = { filename: logoImageId };
  const { data } = await API.graphql<GraphQLQuery<GetUploadLogoUrlQuery>>(
    graphqlOperation(getUploadLogoUrl, {
      input,
    })
  );
  if (data?.getUploadLogoUrl?.url) {
    await fetch(data.getUploadLogoUrl.url, {
      method: "PUT",
      headers: { "Content-Type": fileBlob.type },
      body: fileBlob,
    });
  }
};
