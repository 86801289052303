export const formatCurrency = (value: number | string) => {
  let formattedValue = parseFloat(value.toString()).toLocaleString("en-UK", {
    maximumFractionDigits: 2,
  });
  if (
    formattedValue.split(".").length === 2 &&
    formattedValue.split(".")[1].length === 1
  ) {
    formattedValue = `${formattedValue}0`;
  }
  formattedValue = formattedValue.replace(".00", "");
  return formattedValue;
};
