import { useDispatch, useSelector } from "app/hooks";
import { personaliseReportActions } from "../../app/features/personaliseReport/personaliseReportSlice";
import { logAnalyticsEvent } from "services/analytics";
import { nanoid } from "@reduxjs/toolkit";
import { uploadImage } from "services/image";

export const usePersonaliseReport = () => {
  const dispatch = useDispatch();
  const {
    firmName,
    adviserName,
    logoImage,
    logoScale,
    welcomeNoteSelected,
    welcomeNote,
    finalNoteSelected,
    finalNote,
  } = useSelector((state) => state.personaliseReport);

  const handleFirmNameChange = (firmName: string) => {
    dispatch(personaliseReportActions.updateFirmName(firmName));
  };

  const handleAdviserNameChange = (adviserName: string) => {
    dispatch(personaliseReportActions.updateAdviserName(adviserName));
  };

  const handleLogoImageChange = async (logoImage: string) => {
    if (logoImage) {
      const logoImageId = nanoid();
      dispatch(personaliseReportActions.updateLogoImage(logoImage));
      dispatch(personaliseReportActions.updateLogoScale(100));
      dispatch(personaliseReportActions.updateLogoImageId(logoImageId));
      uploadImage(logoImage, logoImageId);
      return;
    }
    dispatch(personaliseReportActions.updateLogoImage(null));
    dispatch(personaliseReportActions.updateLogoScale(null));
  };

  const handleLogoScaleChange = (scale: number) => {
    dispatch(personaliseReportActions.updateLogoScale(scale));
  };

  const handleUpdateWelcomeNoteSelected = (value: boolean) => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 3",
      label: `Include welcome note | ${value ? "True" : "False"}`,
    });
    dispatch(personaliseReportActions.updateWelcomeNoteSelected(value));
  };

  const handleUpdateWelcomeNote = (value: string) => {
    dispatch(personaliseReportActions.updateWelcomeNote(value));
  };

  const handleUpdateFinalNoteSelected = (value: boolean) => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 3",
      label: `Include final comment | ${value ? "True" : "False"}`,
    });
    dispatch(personaliseReportActions.updateFinalNoteSelected(value));
  };

  const handleUpdateFinalNote = (value: string) => {
    dispatch(personaliseReportActions.updateFinalNote(value));
  };

  return {
    firmName,
    handleFirmNameChange,
    adviserName,
    handleAdviserNameChange,
    logoImage,
    logoScale,
    handleLogoImageChange,
    handleLogoScaleChange,
    welcomeNoteSelected,
    handleUpdateWelcomeNoteSelected,
    welcomeNote,
    handleUpdateWelcomeNote,
    finalNoteSelected,
    handleUpdateFinalNoteSelected,
    finalNote,
    handleUpdateFinalNote,
  };
};
