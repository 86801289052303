import styled from "styled-components";
import { Link as uiLink } from "react-router-dom";
import uiButton from "components/ui/Button";
import { breakpointUp } from "helpers/breakpoints";
import uiCheckbox from "components/ui/Checkbox/Checkbox";
import DownloadReportButton from "./DownloadReportButton/DownloadReportButton";

export const NavigationFooter = styled.div`
  display: block;
  margin: 32px 0 50px 0;

  ${breakpointUp.md`
    display: flex;
    justify-content: space-between;
  `}
`;

export const Button = styled(uiButton)`
  width: 100%;
  margin-bottom: 10px;
  margin-bottom: 10px;

  svg {
    position: relative;
    top: -1px;
  }

  ${breakpointUp.md`
    width: auto;
    margin-bottom: 0;
  `}
`;

export const StartNewReportButton = styled(uiButton)`
  width: 100%;
  margin-bottom: 10px;

  ${breakpointUp.md`
    width: auto;
    margin-left: 10px;
    margin-bottom: 0;
  `}
`;

export const LeftNav = styled.div`
  width: 100%;
`;

export const RightNav = styled.div`
  display: block;

  ${breakpointUp.md`
        text-align: right;
    `}

  ${breakpointUp.lg`
        display: flex;
        text-align: left;
    `}
`;

export const Checkbox = styled(uiCheckbox)`
  min-width: 320px;

  ${breakpointUp.md`
        order: 1;
        margin-top: 12px;
    `}
`;

export const DownloadButton = styled(DownloadReportButton)`
  display: inline-block;
  min-width: 155px;

  ${breakpointUp.sm`
        display: inline-block;
    `}

  ${breakpointUp.lg`
        order: 2;
        margin-left: 10px;
    `}
`;

export const Link = styled(uiLink)`
  :hover {
    text-decoration: none;
  }
`;
