import styled from "styled-components";
import { Container as uiContainer, Col, Row } from "react-bootstrap";
import { breakpointUp } from "../../helpers/breakpoints";

export const StyledCookieBanner = styled(Row)`
  background: #147cb3;
  display: flex;
  flex-direction: row;
  font-family: "Ubuntu", sans-serif;
  bottom: 40px;
  color: #fafafa;
  left: 5%;
  right: 5%;
  position: fixed;
  width: 85%;
  max-width: 75rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  z-index: 100000000;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px 8px;
`;

export const Container = styled(uiContainer)`
  margin: 0;
  padding: 24px 8px;
`;

export const LinkContainer = styled.div`
  margin-bottom: 20px;
  min-width: 150px;

  ${breakpointUp.md`
        margin-bottom: 0;
    `}

  ${breakpointUp.lg`
        margin-right: 10px;
    `}

    ${breakpointUp.xl`
        margin-right: 0;
    `}

    @media only screen and (min-width: 992px) and (max-width: 1080px) {
    width: 100px;
    min-width: initial;
  }

  > a {
    text-decoration: underline;
    color: #fff;
    cursor: pointer;

    ${breakpointUp.lg`
            margin-right: 10px;
        `}

    ${breakpointUp.xl`
            margin-right: 40px;
        `}
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;

  ${breakpointUp.sm`
        flex-direction: row;
        gap:100px;
    `}

  ${breakpointUp.md`
        flex-direction: row;
        gap:40px;
    `}

    > button {
    background-color: white;
    color: #147cb3 !important;
    box-shadow: none !important;
    border: none;
    width: 100%;

    ${breakpointUp.md`
            width: 120px;
        `}

    :hover {
      background-color: white;
      text-decoration: underline;
    }
  }
`;

export const ButtonsCol = styled(Col)`
  ${breakpointUp.md`
        display: flex;
    `}
  ${breakpointUp.lg`
        justify-content: flex-end;
    `}
`;
