import { H3, P } from "components/ui/Typography";
import styled from "styled-components";
import NumericInputGroup from "../forms/NumericInputGroup";
import uiCheckbox from "components/ui/Checkbox/Checkbox";
import uiHelpTooltip from "components/ui/HelpTooltip/HelpTooltip";

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 8px;
  padding: 0 32px 32px 32px;
  margin-bottom: 16px;
`;

export const CardTitle = styled(H3)`
  margin: 0 -32px;
  padding: 10px 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  font-size: 20px;
`;

export const RadioGroup = styled.div`
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 8px;
  padding: 8px;
`;

export const RadioOption = styled.div`
  display: inline-block;

  > label {
    padding-left: 30px;
    margin: 0 10px 0 0;
  }

  &:last-child {
    > label {
      margin-right: 0;
    }
  }
`;

export const Input = styled.input`
  border: 2px solid ${({ theme }) => theme.colors.fieldBorder};
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  max-width: 370px;
  color: ${({ theme }) => theme.colors.bodyText};
`;

export const RadioInput = styled.input`
  position: absolute;
  -webkit-appearance: none;
  appearance: none;
  margin: 2px 0 0 0;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border: 0.15em solid ${({ theme }) => theme.colors.fieldBorder};
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &::before {
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${({ theme }) => theme.colors.primaryAction};
  }

  &:checked {
    border: 0.15em solid ${({ theme }) => theme.colors.primaryAction};
    &::before {
      transform: scale(1);
    }
  }
`;

export const Select = styled.select`
  border: 2px solid ${({ theme }) => theme.colors.fieldBorder};
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  max-width: 370px;
  color: ${({ theme }) => theme.colors.bodyText};
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.29289 6.70711C5.68342 7.09763 6.31658 7.09763 6.70711 6.70711L11.7071 1.70711C12.0976 1.31658 12.0976 0.683418 11.7071 0.292894C11.3166 -0.0976306 10.6834 -0.0976306 10.2929 0.292894L6 4.58579L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893Z' fill='%23051A3F'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
`;

export const Label = styled.label`
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0 8px 0;
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const DrawdownAmount = styled(NumericInputGroup)`
  max-width: 130px;
`;

export const ErrorMessage = styled(P)`
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.error};
`;

export const FieldWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin: 8px 0;
`;

export const ClientFundInput = styled(NumericInputGroup)`
  display: flex;
  flex: 0 0 160px;
  max-width: 370px;
`;

export const ContributionWrapper = styled.div`
  display: inline-block;
  padding: 8px;
  margin-bottom: 8px;
`;

export const InlineLabel = styled.label`
  display: flex;
  height: 44px;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 0px 10px;
  margin: 0px 10px 0 0;
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: ${({ theme }) => theme.colors.paleGrey};
  border-radius: 5px;
`;

export const HelpTooltip = styled(uiHelpTooltip)`
  margin: 12px;
`;

export const Checkbox = styled(uiCheckbox)``;

export const SinglePremiumInput = styled(ClientFundInput)`
  margin-bottom: 18px;
`;

export const RegularPremiumInput = styled(ClientFundInput)``;
