import { breakpointUp } from "helpers/breakpoints";
import styled from "styled-components";
import NumericInputGroup from "../NumericInputGroup";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;

  ${breakpointUp.md`
      max-width: 642px;
  `}

  ${breakpointUp.lg`
      max-width: 722px;
  `}
`;

export const SummaryWrapper = styled.div`
  display: flex;
`;

export const Summary = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 10px;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.bodyText};
  background-color: ${({ theme }) => theme.colors.paleGrey};
  border-radius: 5px;

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }
`;

export const TotalAllocationSummary = styled(Summary)`
  flex: 1;
  min-width: 50%;
  margin-right: 8px;

  ${breakpointUp.sm`
    min-width: auto;
    margin-right: 0;
  `}
`;

export const AMCSummary = styled(Summary)`
  width: 100%;
  margin-top: 0;
  margin-right: 160px;
  flex: 1 0 100%;

  ${breakpointUp.sm`
    flex: initial;
    margin-top: 16px;
  `}
`;

export const Label = styled.p`
  margin: 0;
  flex: 1;
`;

export const Total = styled.p`
  margin: 0;
`;

export const AMCTotal = styled.p`
  margin: 0 10px 0 0;
`;

export const TotalAllocation = styled(NumericInputGroup)`
  min-width: 120px;
  margin: 16px 0;
  max-width: 120px;
  height: 44px;

  ${breakpointUp.sm`
    min-width: auto;
    margin: 16px 32px 0px 8px;
  `}

  > input[type="text"] {
    height: 100%;
    padding-left: 6px;
    padding-right: 50px;
    text-align: center;
  }

  > span {
    height: 100%;
  }
`;
