import Card from "../ui/Card";
import { useCharges } from "./hooks";
import {
  AMCInput,
  FieldWrapper,
  InlineLabel,
  Label,
  AMCRebateInput,
  Summary,
  Total,
  FBRCInput,
  CardContent,
  Checkbox,
  AdditionalExpensesSection,
  AdditionalExpenseInput,
  AdditionalExpensesHeading,
  AdditionalExpensesDescription,
  Symbol,
  ErrorMessage,
} from "./styles";

const ChargesCard = ({ props }: any) => {
  const {
    amc,
    amcRebate,
    updateAMCRebate,
    fbrc,
    updateFBRC,
    effectivePortfolioAMC,
    fundCharges,
    chargesCardDisabled,
    includeAdditionalExpenses,
    updateIncludeAdditionalExpenses,
    blendedAdditionalExpenses,
    errors,
  } = useCharges();

  return (
    <Card title="Charges" disabled={chargesCardDisabled} {...props}>
      <CardContent>
        <Checkbox
          id="include-additional-expenses"
          label="Include additional expenses"
          checked={includeAdditionalExpenses}
          onChange={() =>
            updateIncludeAdditionalExpenses(!includeAdditionalExpenses)
          }
        />

        <FieldWrapper>
          <InlineLabel>
            <div>Blended AMC</div>
          </InlineLabel>

          <AMCInput
            disabled={true}
            suffix="%"
            maxLength={6}
            decimals={2}
            value={(amc || (0 as number)).toFixed(2)}
          />
        </FieldWrapper>

        <FieldWrapper>
          <InlineLabel>
            <div>
              AMC rebate
              <small>
                The Annual Management Charge rebate is applied as credit to the
                policy
              </small>
            </div>
            <Symbol>-</Symbol>
          </InlineLabel>

          <AMCRebateInput
            name="amc"
            suffix="%"
            maxLength={7}
            decimals={2}
            value={amcRebate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateAMCRebate(e.target.value);
            }}
            validationRules={{ min: 0, max: 1.55 }}
          />
        </FieldWrapper>

        {errors.amc && (
          <ErrorMessage>
            The AMC rebate must be between 0% and 1.55%
          </ErrorMessage>
        )}

        <FieldWrapper>
          <InlineLabel>
            <div>
              FBRC
              <small>
                The fund based renewal commission frequency is selected at the
                policy set-up
              </small>
            </div>
            <Symbol>+</Symbol>
          </InlineLabel>

          <FBRCInput
            name="fbrc"
            suffix="%"
            maxLength={6}
            decimals={2}
            value={fbrc}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateFBRC(e.target.value);
            }}
            validationRules={{ min: 0, max: 1 }}
          />
        </FieldWrapper>

        {errors.fbrc && (
          <ErrorMessage>The FBRC must be between 0% and 1%</ErrorMessage>
        )}

        {includeAdditionalExpenses && (
          <AdditionalExpensesSection>
            <AdditionalExpensesHeading>
              Additional expenses
            </AdditionalExpensesHeading>

            <AdditionalExpensesDescription>
              The additional expense percentage for each fund in the portfolio
              are used to calculate the effective portfolio Total Expensive
              Ratio (TER)
            </AdditionalExpensesDescription>

            {fundCharges.map(({ name, additionalExpense }) => (
              <FieldWrapper key={name}>
                <InlineLabel>
                  <div>{name}</div>
                </InlineLabel>

                <AdditionalExpenseInput
                  disabled={true}
                  suffix="%"
                  maxLength={6}
                  decimals={2}
                  value={(additionalExpense as number).toFixed(2).toString()}
                />
              </FieldWrapper>
            ))}

            <Summary>
              <Label>Blended additional expenses</Label>

              <Total>{blendedAdditionalExpenses}%</Total>
            </Summary>
          </AdditionalExpensesSection>
        )}

        <Summary className={effectivePortfolioAMC < 0 ? "error" : ""}>
          <Label>
            Effective portfolio AMC{" "}
            <small>Blended AMC - AMC Rebate + FBRC</small>
          </Label>

          <Total>{effectivePortfolioAMC.toFixed(2)}%</Total>
        </Summary>

        {includeAdditionalExpenses && (
          <Summary
            className={
              effectivePortfolioAMC + blendedAdditionalExpenses < 0
                ? "error"
                : ""
            }
          >
            <Label>
              Effective portfolio TER{" "}
              <small>
                Effective portfolio AMC + Blended additional expenses
              </small>
            </Label>

            <Total>
              {(effectivePortfolioAMC + blendedAdditionalExpenses).toFixed(2)}%
            </Total>
          </Summary>
        )}
      </CardContent>
    </Card>
  );
};
export default ChargesCard;
