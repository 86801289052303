import Checkbox from "components/ui/Checkbox/Checkbox";
import Card from "../ui/Card";
import { usePersonaliseReport } from "./hooks";
import { CharacterCount, Input, Label, LogoUploader, TextArea } from "./styles";

const PersonaliseCard = ({ props }: any) => {
  const {
    firmName,
    handleFirmNameChange,
    adviserName,
    handleAdviserNameChange,
    handleLogoImageChange,
    handleLogoScaleChange,
    welcomeNoteSelected,
    handleUpdateWelcomeNoteSelected,
    welcomeNote,
    handleUpdateWelcomeNote,
    finalNoteSelected,
    handleUpdateFinalNoteSelected,
    finalNote,
    handleUpdateFinalNote,
    logoImage,
    logoScale,
  } = usePersonaliseReport();

  const getCharsRemainingMessage = (value: string, maxChars: number) => {
    const remaining = maxChars - value.length;
    return remaining === 1
      ? `${remaining} character remaining`
      : `${remaining} characters remaining`;
  };

  return (
    <Card title="Personalise" {...props}>
      <Label htmlFor="firmName">Firm name</Label>

      <Input
        autoComplete="off"
        id="firmName"
        value={firmName}
        onChange={(e) => handleFirmNameChange(e.target.value)}
      />

      <Label htmlFor="adviserName">Adviser name</Label>

      <Input
        autoComplete="off"
        id="adviserName"
        value={adviserName}
        onChange={(e) => handleAdviserNameChange(e.target.value)}
      />

      <Label htmlFor="logo">Logo</Label>

      <LogoUploader
        id="logo"
        onChange={handleLogoImageChange}
        onScaleChange={handleLogoScaleChange}
        imageUploadHelpText="Drag a JPG, PNG or SVG under 5mb here to upload your logo."
        scalingHelpText="To achieve the best results, you can scale your logo to fit your report."
        maximumFileSize={5000000}
        resizedImageSize={500}
        imageDataURL={logoImage || undefined}
        imageScale={logoScale || undefined}
      />

      <Checkbox
        id="include-welcome-note"
        label="Include welcome note for your client"
        checked={welcomeNoteSelected}
        onChange={() => handleUpdateWelcomeNoteSelected(!welcomeNoteSelected)}
      />

      {welcomeNoteSelected && (
        <>
          <TextArea
            id="welcome-note"
            value={welcomeNote}
            onChange={(e) => handleUpdateWelcomeNote(e.target.value)}
            maxLength={1000}
          />
          <CharacterCount>
            {getCharsRemainingMessage(welcomeNote, 1000)}
          </CharacterCount>
        </>
      )}

      <Checkbox
        id="include-final-note"
        label="Include final comment/note at the end of the report"
        checked={finalNoteSelected}
        onChange={() => handleUpdateFinalNoteSelected(!finalNoteSelected)}
      />

      {finalNoteSelected && (
        <>
          <TextArea
            id="final-note"
            value={finalNote}
            onChange={(e) => handleUpdateFinalNote(e.target.value)}
            maxLength={1000}
          />
          <CharacterCount>
            {getCharsRemainingMessage(finalNote, 1000)}
          </CharacterCount>
        </>
      )}
    </Card>
  );
};
export default PersonaliseCard;
