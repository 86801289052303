export const withdrawalCappedAt5PercentProducts = [
  "Synergy Investment Bond",
  "Synergy Investment Bond (Corporate)",
];

export const withdrawalProducts = [
  "Synergy ARF",
  "Synergy Investment Bond",
  "Synergy Investment Bond (Corporate)",
  "Synergy PRSA (Vested)",
];

export const contributionProducts = [
  "Synergy Personal Pension",
  "Synergy PRSA",
  "Synergy Regular Invest",
];

export const otherProducts = ["Synergy Buy Out Bond"];
