import { ReactElement, SVGProps } from "react";

const FooterBar = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    // style={{
    //   enableBackground: "new 0 0 1440 134",
    // }}
    viewBox="0 0 1440 134"
    {...props}
  >
    <linearGradient
      id="a"
      x1={1191.483}
      x2={-168.517}
      y1={68.481}
      y2={68.481}
      gradientTransform="rotate(180 590.742 68.481)"
      gradientUnits="userSpaceOnUse"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#ff8a00",
        }}
      />
      <stop
        offset={0.4}
        style={{
          stopColor: "#fb0",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#fd0",
        }}
      />
    </linearGradient>
    <path
      d="M1340 68.5H0"
      style={{
        fill: "none",
        stroke: "url(#a)",
        strokeWidth: 20,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
  </svg>
);
export default FooterBar;
