import { Link } from "react-router-dom";
import { Logo, Title, Wrapper } from "./styles";

const Header = () => (
  <Wrapper>
    <Link
      to="https://www.standardlife.ie"
      title="Visit Standard Life Ireland site"
    >
      <Logo role="presentation" />
    </Link>

    <Title>Investment proposal tool </Title>
  </Wrapper>
);

export default Header;
