import React, { forwardRef } from "react";
import { FormGroup, Input, Symbol } from "./styles";

interface NumericInputGroupProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  decimals?: string | number;
  prefix?: string;
  suffix?: string;
  value?: string;
  defaultEmptyValueToZero?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  validationRules?: object;
}

const NumericInputGroup = (
  {
    prefix,
    suffix,
    className,
    style,
    value,
    validationRules,
    ...otherProps
  }: NumericInputGroupProps,
  ref: any
) => {
  return (
    <FormGroup
      className={className}
      style={style}
      prefix={prefix}
      suffix={suffix}
    >
      <Input
        autoComplete="off"
        ref={ref}
        type="text"
        inputMode="decimal"
        value={value}
        validationRules={validationRules}
        {...otherProps}
      />
      {(!!prefix || !!suffix) && (
        <Symbol role="presentation">{suffix ? suffix : prefix}</Symbol>
      )}
    </FormGroup>
  );
};

export default forwardRef(NumericInputGroup);
