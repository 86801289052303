import { useEffect, useState } from "react";
import { formatCurrency } from "helpers/currency";
import Card from "../ui/Card";
import { useInvestmentAmount } from "./hooks";

import {
  CardContent,
  ErrorMessage,
  FieldWrapper,
  HelpTooltip,
  InlineLabel,
  Label,
  NetAllocationInfo,
  NetAllocationInput,
  Summary,
  Total,
} from "./styles";

const InvestmentAmountCard = ({ props }: any) => {
  const {
    clientFunds,
    regularContribution,
    netAllocation,
    updateNetAllocation,
    investmentAmount,
    isInvestmentOrBondProduct,
    isPersonalPensionProduct,
    investmentAmountCardDisabled,
    errors,
    logTooltipAnalyticsEvent,
  } = useInvestmentAmount();
  const [inputLabelGross, setInputLabelGross] = useState<string>(
    "Investment single premium (gross)"
  );
  const [inputLabelNet, setInputLabelNet] = useState<string>(
    "Investment single premium (net)"
  );
  useEffect(() => {
    const cf = parseFloat(clientFunds || "0");
    const rc = parseFloat(regularContribution || "0");

    if (cf > 0 && rc > 0) {
      setInputLabelGross(
        "Investment single premium and regular premium (gross)"
      );
      setInputLabelNet("Investment single premium and regular premium (net)");
      return;
    }

    if (cf === 0 && rc > 0) {
      setInputLabelGross("Regular premium (gross)");
      setInputLabelNet("Regular premium (net)");
      return;
    }
    setInputLabelGross("Investment single premium (gross)");
    setInputLabelNet("Investment single premium (net)");
  }, [clientFunds, regularContribution]);

  return (
    <Card
      title="Investment amount"
      disabled={investmentAmountCardDisabled}
      {...props}
    >
      <CardContent>
        <Summary className="mb-3 normal">
          <Label>{inputLabelGross}</Label>
          <Total>
            &euro;
            {formatCurrency(
              parseFloat(clientFunds || "0") +
                parseFloat(regularContribution || "0")
            )}
          </Total>
        </Summary>

        <FieldWrapper>
          <InlineLabel>Net allocation</InlineLabel>

          <NetAllocationInput
            name="netAllocation"
            suffix="%"
            maxLength={6}
            decimals={2}
            value={netAllocation}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateNetAllocation(e.target.value);
            }}
            validationRules={{ required: true, min: 92, max: 105 }}
          />

          <HelpTooltip onClick={logTooltipAnalyticsEvent}>
            Percentage of the investment premium that will be allocated to the
            policy after deducting initial commission and where relevant, the 1%
            government levy. Please refer to relevant product guide for
            available allocation
          </HelpTooltip>
        </FieldWrapper>

        {errors.netAllocation && (
          <ErrorMessage>
            The net allocation must be between 92% and 105%
          </ErrorMessage>
        )}

        {isPersonalPensionProduct && (
          <NetAllocationInfo>
            <strong>Note:</strong> The allocation rate inputted applies to both
            the single and regular premium (if chosen).
          </NetAllocationInfo>
        )}

        <Summary>
          <Label>{inputLabelNet}</Label>

          <Total>&euro;{formatCurrency(investmentAmount)}</Total>
        </Summary>

        {isInvestmentOrBondProduct && (
          <NetAllocationInfo>
            <strong>Life Assurance Premium Levy:</strong> A 1% government levy
            applies to all investment products sold in Ireland. Remember to
            factor this in when calculating the net allocation.
          </NetAllocationInfo>
        )}
      </CardContent>
    </Card>
  );
};
export default InvestmentAmountCard;
