import Button from "components/ui/Button";
import styled from "styled-components";
import ReactSlider from "react-slider";
import { rgba } from "polished";
import FileIcon from "./FileIcon";

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const FileUpload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.paleGrey};
  border: 1px dashed ${({ theme }) => theme.colors.cardBorder};
  border-radius: 5px;
  padding: 30px 10px;
`;

export const FileUploadIcon = styled(FileIcon)`
  width: 32px;
  height: auto;
  margin-bottom: 20px;
`;

export const Separator = styled.p`
  font-size: 12px;
  line-height: 17px;
  text-align: center;

  &:before,
  &:after {
    position: relative;
    content: " ";
    display: inline-block;
    top: -4px;
    width: 80px;
    margin: 0 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  }
`;

export const UploadButton = styled(Button)`
  margin-top: 10px;
  height: 34px;
`;

export const UploadMessage = styled.p`
  font-size: 12px;
  line-height: 17px;
  max-width: 200px;
  text-align: center;
`;

export const ImagePreview = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 25px 50px; */
  /* background-color: ${({ theme }) => theme.colors.paleGrey}; */
  /* border: 2px solid ${({ theme }) => theme.colors.fieldBorder}; */
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  background-color: ${({ theme }) => theme.colors.paleGrey};
  border-radius: 5px;
  min-height: 160px;
  padding: 20px 0;
  margin-bottom: 10px;

  /* &:after {
        position: absolute;
        display: block;
        content: " ";
        top: 25px;
        left: 50px;
        right: 50px;
        bottom: 25px;
        border: 1px dashed ${({ theme }) => theme.colors.primaryAction};
        border-radius: 5px;
        z-index: 1;
    } */
`;

export const ImageScaling = styled.div``;

export const DeleteButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${({ theme }) => rgba(theme.colors.primaryAction, 0.15)};

  > svg {
    height: 14px;
  }
`;

export const Image = styled.img`
  height: auto;
  width: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Slider = styled(ReactSlider)`
  .slider-thumb {
    background-color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.primaryAction};
  }

  .slider-track {
    height: 4px;
    margin-top: 8px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.primaryAction};
  }

  .slider-track-0 {
    background-color: ${({ theme }) => theme.colors.primaryAction};
  }

  .slider-track-1 {
    background-color: ${({ theme }) => theme.colors.cardBorder};
  }
`;

export const ScalingHelpText = styled.p`
  line-height: 120%;
`;

export const SliderLabel = styled.p`
  font-size: 14px;
  margin: 0;
`;
