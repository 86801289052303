import React, { useState } from "react";
import { Combobox } from "@headlessui/react";
import {
  Input,
  AutoCompleteOption,
  AutoCompleteOptions,
  OptionSpan,
  Wrapper,
  Spinner,
  DropdownListWrapper,
  NoMatchesLabel,
} from "./styles";

export type OptionType = {
  label: string;
  value: string;
  disabled: boolean;
};

interface AutoCompleteProps {
  options: OptionType[];
  onChange?: Function;
  placeholder?: string;
  name?: string;
  noMatchesLabel?: String;
  loading?: boolean;
}

const AutoComplete = ({
  options = [],
  onChange,
  placeholder,
  name,
  noMatchesLabel = "No matches found",
  loading,
  ...otherProps
}: AutoCompleteProps) => {
  const [selectedOption, setSelectedOption] = useState({
    label: null,
    value: null,
  });
  const [query, setQuery] = useState("");

  const filteredOptions =
    query === ""
      ? options
      : options.filter(({ label }) => {
          return label.toLowerCase().includes(query.toLowerCase());
        });

  const handleChange = (item: any) => {
    setSelectedOption(item);
    if (typeof onChange === "function") {
      onChange(item);
    }
  };

  return (
    <Wrapper {...otherProps}>
      <Combobox value={selectedOption} onChange={handleChange}>
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setQuery(e.target.value)
          }
          placeholder={placeholder}
          name={name}
        />
        <AutoCompleteOptions>
          {filteredOptions.map((item, key) => (
            <AutoCompleteOption
              id={`fund-${key}`}
              key={item.value}
              value={item}
              disabled={item.disabled}
            >
              {({ selected, active, disabled }) => (
                <>
                  <OptionSpan
                    selected={selected}
                    active={active}
                    disabled={disabled}
                  >
                    {item.label}
                  </OptionSpan>
                </>
              )}
            </AutoCompleteOption>
          ))}
          {!loading && filteredOptions.length === 0 && (
            <DropdownListWrapper>
              <NoMatchesLabel>{noMatchesLabel}</NoMatchesLabel>
            </DropdownListWrapper>
          )}
          {loading && (
            <DropdownListWrapper>
              <Spinner />
            </DropdownListWrapper>
          )}
        </AutoCompleteOptions>
      </Combobox>
    </Wrapper>
  );
};

export default AutoComplete;
