import styled from "styled-components";
import { Link as uiLink } from "react-router-dom";
import uiButton from "components/ui/Button";
import { breakpointUp } from "helpers/breakpoints";

export const NavigationFooter = styled.div`
  display: block;
  margin: 32px 0 50px 0;

  ${breakpointUp.md`
    display: flex;
    justify-content: space-between;
  `}
`;

export const Button = styled(uiButton)`
  width: 100%;
  margin-bottom: 10px;

  ${breakpointUp.md`
    width: auto;
    margin-bottom: 0;
  `}
`;

export const Link = styled(uiLink)`
  width: 100%;

  :hover {
    text-decoration: none;
  }

  ${breakpointUp.md`
    width: auto;
  `}
`;
