import styled from "styled-components";
import uiButton from "components/ui/Button";
import { Dialog as uiDialog } from "@headlessui/react";
import { breakpointUp } from "helpers/breakpoints";
import uiDownloadIcon from "components/icons/DownloadIcon";

export const Button = styled(uiButton)`
  width: 100%;
  margin-bottom: 10px;

  svg {
    position: relative;
    top: -1px;
  }

  ${breakpointUp.md`
    width: auto;
    margin-bottom: 0;
  `}
`;

export const Dialog = styled(uiDialog)`
  display: none;
  position: relative;
  z-index: 1000;

  ${breakpointUp.sm`
    display: block;
  `}
`;

export const PanelWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
`;

export const Panel = styled.div`
  position: relative;
  width: 100%;
  max-width: 770px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  text-align: center;
  padding: 0;
`;

export const Title = styled(uiDialog.Title)`
  font-family: Ubuntu, sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  padding: 22px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const ModalButton = styled(uiButton)`
  margin-left: 10px;
`;

export const ModalContent = styled.div`
  margin: 32px;
`;

export const FeatureCard = styled.div`
  display: flex;
  padding: 24px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.featureCard};
  border-radius: 5px;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 80px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 24px;
  background-color: ${({ theme }) => theme.colors.white};

  svg {
    width: 65%;
  }
`;

export const Description = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.colors.primaryText};

  p:last-child {
    margin-bottom: 0;
  }
`;

export const FeatureHeading = styled.p`
  font-weight: bold;
`;

export const ModalNav = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 32px 32px 0;
`;

export const DownloadIcon = styled(uiDownloadIcon)``;

export const DownloadLink = styled.a`
  outline: none;
`;
