import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UpdateSectionPayload {
  section: string;
  value: boolean;
}

interface InitialState {
  logoImageId: string | null;
  firmName: string;
  adviserName: string;
  logoImage: string | null;
  logoScale: number | null;
  welcomeNoteSelected: boolean;
  welcomeNote: string;
  finalNoteSelected: boolean;
  finalNote: string;
  includeFundDescriptionsSection: boolean;
  includeHighLevelAssetAllocationSection: boolean;
  includeRegionalEquityBreakdownSection: boolean;
  includeDiscretePortfolioPerformanceSection: boolean;
  includeIndividualFundPerformanceSection: boolean;
  includeRiskProfileSection: boolean;
  includeTermsAndChargesSection: boolean;
}

const initialState: InitialState = {
  firmName: localStorage.getItem("firmName") || "",
  adviserName: localStorage.getItem("adviserName") || "",
  logoImageId: null,
  logoImage: localStorage.getItem("logoImage") || null,
  logoScale: localStorage.getItem("logoScale")
    ? parseInt(localStorage.getItem("logoScale") as string)
    : null,
  welcomeNoteSelected: false,
  welcomeNote: "",
  finalNoteSelected: false,
  finalNote: "",
  includeFundDescriptionsSection: true,
  includeHighLevelAssetAllocationSection: true,
  includeRegionalEquityBreakdownSection: true,
  includeDiscretePortfolioPerformanceSection: true,
  includeIndividualFundPerformanceSection: true,
  includeRiskProfileSection: true,
  includeTermsAndChargesSection: true,
};

const personaliseReportSlice = createSlice({
  name: "personaliseReport",
  initialState,
  reducers: {
    updateFirmName: (state, action: PayloadAction<string>) => {
      state.firmName = action.payload;
    },
    updateAdviserName: (state, action: PayloadAction<string>) => {
      state.adviserName = action.payload;
    },
    updateLogoImageId: (state, action: PayloadAction<string | null>) => {
      state.logoImageId = action.payload;
    },
    updateLogoImage: (state, action: PayloadAction<string | null>) => {
      state.logoImage = action.payload;
    },
    updateLogoScale: (state, action: PayloadAction<number | null>) => {
      state.logoScale = action.payload;
    },
    updateWelcomeNoteSelected: (state, action: PayloadAction<boolean>) => {
      state.welcomeNoteSelected = action.payload;
    },
    updateWelcomeNote: (state, action: PayloadAction<string>) => {
      state.welcomeNote = action.payload;
    },
    updateFinalNoteSelected: (state, action: PayloadAction<boolean>) => {
      state.finalNoteSelected = action.payload;
    },
    updateFinalNote: (state, action: PayloadAction<string>) => {
      state.finalNote = action.payload;
    },
    updateReportSection: (
      state,
      action: PayloadAction<UpdateSectionPayload>
    ) => {
      const { section, value } = action.payload;
      (state as any)[section] = value;
    },
    reset: (state) => {
      state.firmName = initialState.firmName;
      state.adviserName = initialState.adviserName;
      state.logoImageId = initialState.logoImageId;
      state.logoImage = initialState.logoImage;
      state.logoScale = initialState.logoScale;
      state.welcomeNoteSelected = initialState.welcomeNoteSelected;
      state.welcomeNote = initialState.welcomeNote;
      state.finalNoteSelected = initialState.finalNoteSelected;
      state.finalNote = initialState.finalNote;
      state.includeFundDescriptionsSection =
        initialState.includeFundDescriptionsSection;
      state.includeHighLevelAssetAllocationSection =
        initialState.includeHighLevelAssetAllocationSection;
      state.includeRegionalEquityBreakdownSection =
        initialState.includeRegionalEquityBreakdownSection;
      state.includeDiscretePortfolioPerformanceSection =
        initialState.includeDiscretePortfolioPerformanceSection;
      state.includeIndividualFundPerformanceSection =
        initialState.includeIndividualFundPerformanceSection;
      state.includeRiskProfileSection = initialState.includeRiskProfileSection;
      state.includeTermsAndChargesSection =
        initialState.includeTermsAndChargesSection;
    },
  },
});

export default personaliseReportSlice.reducer;
export const personaliseReportActions = personaliseReportSlice.actions;
