import React, {
  ChangeEvent,
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";

interface NumericInputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string | number | readonly string[] | undefined;
  decimals?: string | number;
  thousandsSeparator?: boolean;
  defaultEmptyValueToZero?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  onFocus?: FocusEventHandler<HTMLInputElement> | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  validationRules?: object;
  disabled?: boolean;
}

const formatInputValue = (value: string, precision: number) => {
  let formattedValue = parseFloat(value).toLocaleString("en-UK", {
    maximumFractionDigits: precision,
  });
  if (value.indexOf(".") !== -1) {
    const fraction = value.split(".")[1];
    if (value.endsWith(".")) {
      formattedValue = formattedValue.split(".")[0];
    } else {
      formattedValue = `${formattedValue.split(".")[0]}.${fraction}`;
    }
  }
  return formattedValue;
};

const NumericInput = (
  {
    decimals = 0,
    thousandsSeparator = true,
    defaultEmptyValueToZero,
    onChange,
    onBlur,
    onFocus,
    value = "",
    name,
    validationRules,
    disabled,
    ...otherProps
  }: NumericInputProps,
  ref: any
) => {
  const [val, setVal] = useState(value.toString());
  const [hasFocus, setHasFocus] = useState(false);
  const ctx = useFormContext();
  const precision = parseInt(decimals.toString(), 10);

  useEffect(() => {
    if (
      disabled &&
      parseFloat(val.toString()) !== parseFloat(value.toString())
    ) {
      setVal(value.toString());
    }
  }, [value, val, disabled]);

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: ChangeEventHandler<HTMLInputElement> | undefined
  ) => {
    let value;
    if (precision === 0) {
      value = e.target.value.split(".")[0].replace(/\D/g, "");
    } else {
      value = e.target.value.replace(/[^\d.]/g, "");
      if (value.split(".").length > 1) {
        let [integer, fraction] = value.split(".");
        integer = integer.length ? integer : "0";
        value = `${parseInt(integer, 10)}.${fraction.substring(0, precision)}`;
      } else if (value.indexOf(".") === -1 && value.length > 0) {
        value = parseInt(value, 10).toString();
      }
    }
    setVal(value);
    e.target.value = value;
    if (typeof onChange === "function") {
      onChange(e);
    }
  };

  const handleOnFocus = (
    e: ChangeEvent<HTMLInputElement>,
    onFocus: Function | undefined
  ) => {
    setHasFocus(true);
    let value = e.target.value.replace(/,/g, "");
    e.target.value = value;
    setVal(value);
    if (typeof onFocus === "function") {
      onFocus(e);
    }
  };

  const handleOnBlur = (
    e: ChangeEvent<HTMLInputElement>,
    onBlur: Function | undefined
  ) => {
    setHasFocus(false);
    if (typeof onBlur === "function") {
      e.target.value = e.target.value.replace(/,/g, "");
      onBlur(e);
    }
    let value = e.target.value;
    if (value === "" || !thousandsSeparator) {
      if (defaultEmptyValueToZero && value === "") {
        setVal("0");
      } else {
        setVal(value);
      }
    } else {
      const localString = parseFloat(value).toLocaleString("en-UK", {
        maximumFractionDigits: precision,
      });
      if (value.indexOf(".") !== -1) {
        const fraction = value.split(".")[1];
        if (value.endsWith(".")) {
          setVal(localString.split(".")[0]);
          e.target.value = localString.split(".")[0];
          if (typeof onChange === "function") {
            onChange(e);
          }
        } else {
          setVal(`${localString.split(".")[0]}.${fraction}`);
        }
      } else {
        setVal(value);
      }
      e.target.value = localString;
    }
  };

  const fieldValue =
    !hasFocus && !isNaN(parseFloat(val))
      ? formatInputValue(val, precision)
      : val;

  if (validationRules) {
    const {
      onBlur: registerOnBlur,
      onChange: registerOnChange,
      ref: registerRef,
      ...registerProps
    } = ctx.register(name || "", validationRules);
    return (
      <input
        autoComplete="off"
        ref={registerRef}
        value={fieldValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleOnChange(e, onChange);
          registerOnChange(e);
        }}
        onFocus={(e: FocusEvent<HTMLInputElement>) => handleOnFocus(e, onFocus)}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          handleOnBlur(e, onBlur);
          registerOnBlur(e);
        }}
        disabled={disabled}
        {...registerProps}
        {...otherProps}
      />
    );
  }

  return (
    <input
      autoComplete="off"
      ref={ref}
      value={fieldValue}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        handleOnChange(e, onChange)
      }
      onFocus={(e: FocusEvent<HTMLInputElement>) => handleOnFocus(e, onFocus)}
      onBlur={(e: FocusEvent<HTMLInputElement>) => handleOnBlur(e, onBlur)}
      disabled={disabled}
      {...otherProps}
    />
  );
};

export default forwardRef(NumericInput);
