import dayjs from "dayjs";

export const getMaximumAvailableReportingPeriod = (inceptionDate: string) => {
  return dayjs().startOf("month").diff(dayjs(inceptionDate), "years");
};

export const getReportingPeriod = (years: number) => {
  const startOfCurrentMonth = dayjs().startOf("month");
  const startDate = startOfCurrentMonth
    .subtract(years, "years")
    .format("YYYY-MM-DD");
  const endDate = startOfCurrentMonth.subtract(1, "days").format("YYYY-MM-DD");
  return { startDate, endDate };
};
