import styled from "styled-components";
import { H3 } from "../Typography";

export const CardTitle = styled(H3)`
  margin: 0 -32px;
  padding: 10px 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  font-size: 20px;
  color: ${({ theme }) => theme.colors.cardTitle};
`;

export const CardWrapper = styled(({ disabled, children, ...otherProps }) => (
  <div {...otherProps}>{children}</div>
))`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 8px;
  padding: ${({ disabled }) => (disabled ? "0 32px" : "0 32px 32px 32px")};
  margin-bottom: 16px;

  ${CardTitle} {
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
    border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
    border-width: ${({ disabled }) => (disabled ? "0" : "1px")};
    margin-bottom: ${({ disabled }) => (disabled ? "0" : "32px")};
  }
`;
