import NumericInputGroup from "components/forms/NumericInputGroup";
import styled from "styled-components";
import uiHelpTooltip from "components/ui/HelpTooltip/HelpTooltip";
import { breakpointUp } from "helpers/breakpoints";
import { P } from "components/ui/Typography";

export const GovernmentLevyMessage = styled.p`
  margin-top: 10px;
`;

export const ExitTaxMessage = styled.p`
  margin-top: 10px;
`;

export const NetAllocationWrapper = styled.div`
  display: flex;
  margin-bottom: 14px;
  max-width: 690px;
`;

export const InlineLabel = styled.label`
  display: flex;
  flex: 1;
  height: 44px;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 0px 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: ${({ theme }) => theme.colors.paleGrey};
  border-radius: 5px;
`;

export const NetAllocationInput = styled(NumericInputGroup)`
  min-width: 120px;
  margin: 16px 0;
  max-width: 120px;
  height: 44px;

  ${breakpointUp.sm`
    min-width: auto;
    margin: 0 0 0px 8px;
  `}

  > input[type="text"] {
    height: 100%;
    padding-left: 6px;
    padding-right: 50px;
    text-align: center;
  }

  > span {
    height: 100%;
  }
`;

export const HelpTooltip = styled(uiHelpTooltip)`
position: absolute;
right: -37px;
margin: 12px;
max - width: 400px;

  ${breakpointUp.lg`
    right: -40px;
  `}
`;

export const ErrorMessage = styled(P)`
margin - top: 5px;
color: ${({ theme }) => theme.colors.error};
`;

export const Label = styled.p`
  margin: 0;
  flex: 1;
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: 16px;
  margin-bottom: 6px;
  font-weight: bold;
`;

export const NetAllocationInfo = styled(P)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primaryText};
`;
