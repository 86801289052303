import styled from "styled-components";
import uiHelpTooltip from "components/ui/HelpTooltip/HelpTooltip";
import { P } from "components/ui/Typography";
import { breakpointUp } from "helpers/breakpoints";

export const Card = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 8px;
  padding: 32px;
  margin-bottom: 16px;
`;

export const IntroMessage = styled(P)`
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const HelpTooltip = styled(uiHelpTooltip)`
  position: relative;
  top: 4px;
  left: 10px;
`;

export const SectionList = styled.div``;

export const SectionListHeader = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.paleGrey};
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.cardTitle};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 8px 8px 0 0;
`;

export const SectionHeaderCol = styled.div`
  flex: 1;
  min-height: 54px;
  line-height: 54px;
  border-top-left-radius: 5px;
  padding-left: 32px;
`;

export const IncludeHeaderCol = styled.div`
  flex: 0 1 70px;
  min-height: 54px;
  line-height: 54px;
  border-top-right-radius: 5px;
  padding-right: 32px;

  ${breakpointUp.sm`
    flex: 0 1 120px;
  `}
`;
