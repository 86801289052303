import { useDispatch, useSelector } from "app/hooks";
import { investmentFundsActions } from "../../../app/features/investmentFunds/investmentFundsSlice";
import { Fund } from "../../../types/types";
import { logAnalyticsEvent } from "services/analytics";

const roundToDecimalPlaces = (value: number, decimals: number): number =>
  Math.round((value + Number.EPSILON) * Math.pow(10, decimals)) /
  Math.pow(10, decimals);

export const useFundList = () => {
  const dispatch = useDispatch();
  const allFunds = useSelector((state) => state.investmentFunds.availableFunds);
  const selectedFunds = useSelector(
    (state) => state.investmentFunds.selectedFunds
  );
  const totalAllocation = useSelector(
    (state) => state.investmentFunds.totalAllocation
  );
  const { averageAMC } = useSelector((state) => state.investmentFunds);

  const amc = averageAMC ? roundToDecimalPlaces(averageAMC, 2) : averageAMC;

  const availableFunds = [];

  for (const fund of allFunds || []) {
    if (
      selectedFunds.filter(({ citicode }: Fund) => fund.citicode === citicode)
        .length === 0
    ) {
      availableFunds.push(fund);
    }
  }

  const handleChange = (citicode: string) => {
    const fund = allFunds?.find((fnd) => fnd.citicode === citicode);
    if (fund) {
      dispatch(investmentFundsActions.addFund({ ...fund }));
    }
  };

  const updateFundAllocation = (fund: Fund) => {
    dispatch(investmentFundsActions.updateFundAllocation(fund));
  };

  const removeFund = (fund: Fund) => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 1",
      label: "Fund Delete",
    });
    dispatch(investmentFundsActions.removeFund(fund));
  };

  return {
    availableFunds,
    selectedFunds,
    totalAllocation,
    handleChange,
    updateFundAllocation,
    removeFund,
    amc,
  };
};
