import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Fund } from "types/types";

const safelyAddDecimals = (a: number, b: number) =>
  parseFloat((a + b).toFixed(10));

interface InitialState {
  availableFunds?: Fund[];
  selectedFunds: Fund[];
  totalAllocation: number;
  averageAMC?: number;
  reportingPeriod?: number;
}

const initialState: InitialState = {
  availableFunds: undefined,
  selectedFunds: [],
  totalAllocation: 0,
  averageAMC: undefined,
  reportingPeriod: undefined,
};

const calculateAverageAMC = (funds: Fund[]) => {
  let averageAMC = 0;
  const totalAllocation = funds.reduce(
    (sum, fund) => safelyAddDecimals(sum, fund.allocation || 0),
    0
  );
  if (totalAllocation !== 100) {
    return undefined;
  }
  for (const fund of funds) {
    const allocation = fund.allocation || 0;
    const fundAMC = parseFloat((fund.amc || "0").replace("%", ""));
    averageAMC = safelyAddDecimals(averageAMC, (fundAMC * allocation) / 100);
  }
  return averageAMC;
};

const investmentFundsSlice = createSlice({
  name: "funds",
  initialState,
  reducers: {
    addAllFunds: (state, action: PayloadAction<Fund[]>) => {
      const funds = action.payload;
      state.availableFunds = funds;
    },
    addFund: (state, action: PayloadAction<Fund>) => {
      const fund = action.payload;
      fund.allocation = undefined;
      state.selectedFunds.push(fund);
      state.averageAMC = calculateAverageAMC(state.selectedFunds);
      state.reportingPeriod = undefined;
    },
    removeFund: (state, action: PayloadAction<Fund>) => {
      state.selectedFunds = state.selectedFunds.filter(
        ({ citicode }) => citicode !== action.payload.citicode
      );
      state.totalAllocation = state.selectedFunds.reduce(
        (sum, fund) => sum + (fund.allocation || 0),
        0
      );
      state.averageAMC = calculateAverageAMC(state.selectedFunds);
      state.reportingPeriod = undefined;
    },
    updateFundAllocation: (state, action: PayloadAction<Fund>) => {
      for (const fund of state.selectedFunds) {
        if (fund.citicode === action.payload.citicode) {
          fund.allocation = action.payload.allocation;
        }
      }
      state.totalAllocation = state.selectedFunds.reduce(
        (sum, fund) => safelyAddDecimals(sum, fund.allocation || 0),
        0
      );
      state.averageAMC = calculateAverageAMC(state.selectedFunds);
    },
    updateReportingPeriod: (state, action: PayloadAction<number>) => {
      state.reportingPeriod = action.payload;
    },
    reset: (state) => {
      state.availableFunds = initialState.availableFunds;
      state.selectedFunds = initialState.selectedFunds;
      state.totalAllocation = initialState.totalAllocation;
      state.averageAMC = initialState.averageAMC;
      state.reportingPeriod = initialState.reportingPeriod;
    },
  },
});

export default investmentFundsSlice.reducer;
export const investmentFundsActions = investmentFundsSlice.actions;
