import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  product: string;
  clientName: string;
  hasDrawdown?: boolean;
  hasSingle?: boolean;
  hasRegular?: boolean;
  drawdownPercent: number;
  drawdownMonetaryAmount: number;
  drawdownType?: string;
}

const initialState: InitialState = {
  product: "",
  clientName: "",
  hasDrawdown: undefined,
  hasSingle: undefined,
  hasRegular: undefined,
  drawdownType: undefined,
  drawdownPercent: 0,
  drawdownMonetaryAmount: 0,
};

const investmentDetailsSlice = createSlice({
  name: "investmentDetails",
  initialState,
  reducers: {
    updateClientName: (state, action: PayloadAction<string>) => {
      state.clientName = action.payload;
    },
    updateProduct: (state, action: PayloadAction<string>) => {
      state.product = action.payload;
      state.hasDrawdown = undefined;
      state.drawdownPercent = 0;
      state.drawdownMonetaryAmount = 0;
    },
    updateHasDrawdown: (state, action: PayloadAction<boolean>) => {
      state.hasDrawdown = action.payload;
    },
    updateHasSinglePremium: (state, action: PayloadAction<boolean>) => {
      state.hasSingle = action.payload;
    },
    updateHasRegularPremium: (state, action: PayloadAction<boolean>) => {
      state.hasRegular = action.payload;
    },
    updateDrawdownType: (state, action: PayloadAction<string>) => {
      state.drawdownType = action.payload;
    },
    updateDrawdownPercent: (state, action: PayloadAction<number>) => {
      state.drawdownPercent = action.payload;
    },
    updateDrawdownMonetaryAmount: (state, action: PayloadAction<number>) => {
      state.drawdownMonetaryAmount = action.payload;
    },
    reset: (state) => {
      state.product = initialState.product;
      state.clientName = initialState.clientName;
      state.hasDrawdown = initialState.hasDrawdown;
      state.hasRegular = initialState.hasRegular;
      state.hasSingle = initialState.hasSingle;
      state.drawdownPercent = initialState.drawdownPercent;
      state.drawdownMonetaryAmount = initialState.drawdownMonetaryAmount;
    },
  },
});

export default investmentDetailsSlice.reducer;
export const investmentDetailsActions = investmentDetailsSlice.actions;
