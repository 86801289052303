import { useEffect, useRef, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { generateReportPDFRequest } from "graphql/mutations";
import { onGenerateReportPDFRequest } from "graphql/subscriptions";
import { useSelector } from "app/hooks";
import { logAnalyticsEvent } from "services/analytics";
import { contributionProducts, withdrawalProducts } from "helpers/products";

export const useDownloadReportButton = () => {
  const timer = useRef();
  const {
    clientName,
    product,
    drawdownPercent,
    hasDrawdown,
    drawdownType,
    drawdownMonetaryAmount,
    hasRegular,
    hasSingle,
  } = useSelector((state) => state.investmentDetails);
  const {
    clientFunds,
    investmentAmount: totalInvestmentAmount,
    netAllocation,
  } = useSelector((state) => state.investmentAmount);
  const { selectedFunds: funds, reportingPeriod } = useSelector(
    (state) => state.investmentFunds
  );
  const { amcRebate, fbrc } = useSelector((state) => state.charges);
  const selectedFunds = funds
    .filter(({ allocation }) => allocation && allocation > 0)
    .map(({ name, citicode, allocation, amc }) => ({
      name,
      citicode,
      allocation: allocation as number,
      amc: parseFloat((amc as string)?.replace("%", "")),
    }));

  const {
    adviserName,
    firmName: adviserFirm,
    welcomeNoteSelected,
    welcomeNote,
    finalNoteSelected,
    finalNote,
    logoImageId,
    logoImage,
    logoScale,
    includeFundDescriptionsSection,
    includeHighLevelAssetAllocationSection,
    includeRegionalEquityBreakdownSection,
    includeDiscretePortfolioPerformanceSection,
    includeIndividualFundPerformanceSection,
    includeRiskProfileSection,
    includeTermsAndChargesSection,
  } = useSelector((state) => state.personaliseReport);

  const { includeAdditionalExpenses } = useSelector((state) => state.charges);

  let [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  let [reportUrl, setReportUrl] = useState<string | undefined>();

  function closeModal() {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 3",
      label: "Cancel button",
    });
    setIsModalOpen(false);
    setReportUrl(undefined);
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }

  function viewReport() {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 3",
      label: "View report button",
    });
    closeModal();
  }

  type GenerateReportPDFRequestMutation = {
    generateReportPDFRequest: {
      __typename: "generateReportPDFRequest";
      ID: string;
      Status: string;
      url: string;
    };
  };

  type Fund = {
    name: String;
    citicode: String;
    allocation: Number;
  };

  type ReportResultPDFInput = {
    logoImageId: String | null;
    logoScale: number | null;
    clientName: String;
    product: String;
    drawdownPercent: Number | undefined;
    drawdownMonetaryAmount: Number | undefined;
    adviserName: String;
    adviserFirm: String;
    welcomeNote: String;
    finalNote: String;
    selectedFunds: Fund[];
    clientFunds: Number;
    totalInvestmentAmount: Number;
    netAllocation: Number;
    amcRebate: Number;
    fbrc: Number;
    reportingPeriod: Number;
    includeAdditionalExpenses: Boolean;
    includeFundDescriptionsSection: Boolean;
    includeHighLevelAssetAllocationSection: Boolean;
    includeRegionalEquityBreakdownSection: Boolean;
    includeDiscretePortfolioPerformanceSection: Boolean;
    includeIndividualFundPerformanceSection: Boolean;
    includeRiskProfileSection: Boolean;
    includeTermsAndChargesSection: Boolean;
  };

  useEffect(() => {
    const timerValue = timer.current;
    return () => {
      if (timer) {
        clearTimeout(timerValue);
      }
    };
  }, []);

  const handleCreateReport = async () => {
    setReportUrl(undefined);
    setIsModalOpen(true);
    // automatically close the modal after 5 minutes as the pdf signed url will have expired
    (timer.current as any) = setTimeout(
      () => setIsModalOpen(false),
      5 * 60 * 1000
    );

    logAnalyticsEvent({
      category: "Report details",
      action: "Page 3",
      label: "Create report button",
    });

    if (localStorage.getItem("rememberCustomisationOptions") === "true") {
      if (logoImage) {
        localStorage.setItem("logoImage", logoImage);
        localStorage.setItem(
          "logoScale",
          logoScale ? logoScale.toString() : "100"
        );
      } else {
        localStorage.removeItem("logoImage");
        localStorage.removeItem("logoScale");
      }
      localStorage.setItem("firmName", adviserFirm.trim());
      localStorage.setItem("adviserName", adviserName.trim());
    } else {
      localStorage.removeItem("logoImage");
      localStorage.removeItem("logoScale");
      localStorage.removeItem("firmName");
      localStorage.removeItem("adviserName");
    }

    const input: ReportResultPDFInput = {
      logoImageId,
      logoScale,
      clientName,
      product,
      drawdownPercent:
        hasDrawdown &&
        drawdownType === "percent" &&
        withdrawalProducts.includes(product)
          ? drawdownPercent
          : undefined,
      drawdownMonetaryAmount:
        (hasDrawdown &&
          drawdownType === "monetary" &&
          withdrawalProducts.includes(product)) ||
        (contributionProducts.includes(product) && hasRegular)
          ? drawdownMonetaryAmount
          : undefined,
      adviserName,
      adviserFirm,
      welcomeNote: welcomeNoteSelected ? welcomeNote : "",
      finalNote: finalNoteSelected ? finalNote : "",
      selectedFunds,
      clientFunds:
        contributionProducts.includes(product) && hasSingle === false
          ? 0
          : parseFloat(clientFunds),
      totalInvestmentAmount:
        contributionProducts.includes(product) && hasSingle === false
          ? 0
          : totalInvestmentAmount,
      netAllocation: parseFloat(netAllocation),
      amcRebate: parseFloat(amcRebate),
      fbrc: parseFloat(fbrc),
      reportingPeriod: reportingPeriod as number,
      includeAdditionalExpenses,
      includeFundDescriptionsSection,
      includeHighLevelAssetAllocationSection,
      includeRegionalEquityBreakdownSection,
      includeDiscretePortfolioPerformanceSection,
      includeIndividualFundPerformanceSection,
      includeRiskProfileSection,
      includeTermsAndChargesSection,
    };

    const { data } = await API.graphql<
      GraphQLQuery<GenerateReportPDFRequestMutation>
    >(
      graphqlOperation(generateReportPDFRequest, {
        input,
      })
    );
    const subscription = (
      API.graphql(
        graphqlOperation(onGenerateReportPDFRequest, {
          ID: data?.generateReportPDFRequest.ID,
        })
      ) as any
    ).subscribe({
      next: (result: any) => {
        if (result.value.data.onGenerateReportPDFRequest.url) {
          const { url } = result.value.data.onGenerateReportPDFRequest;
          setReportUrl(url);
        }
        subscription.unsubscribe();
      },
      error: ({ error }: any) => console.warn(error.errors[0]),
    });
  };

  return {
    isModalOpen,
    closeModal,
    reportUrl,
    handleCreateReport,
    viewReport,
  };
};
