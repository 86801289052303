import { useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "app/hooks";
import { investmentFundsActions } from "../../app/features/investmentFunds/investmentFundsSlice";
import { getFunds } from "../../graphql/queries";
import { GetFundsQuery } from "API";
import { getMaximumAvailableReportingPeriod } from "helpers/reportingPeriod";
import { contributionProducts } from "helpers/products";

export const useInvestmentFunds = () => {
  const dispatch = useDispatch();
  const {
    clientName,
    product,
    hasDrawdown,
    drawdownPercent,
    hasSingle,
    hasRegular,
  } = useSelector((state) => state.investmentDetails);
  const { availableFunds } = useSelector((state) => state.investmentFunds);

  const showLevyMessage =
    ["Synergy Investment Bond (Corporate)", "Synergy Investment Bond"].indexOf(
      product
    ) !== -1;

  const investmentFundsCardDisabled =
    !clientName ||
    !product ||
    (product === "Synergy ARF" && hasDrawdown === undefined) ||
    (contributionProducts.includes(product) && !hasSingle && !hasRegular) ||
    drawdownPercent > 100;

  useEffect(() => {
    const loadFunds = async (): Promise<void> => {
      const { data } = await API.graphql<GraphQLQuery<GetFundsQuery>>(
        graphqlOperation(getFunds, {})
      );
      const funds = data?.getFunds
        ? data.getFunds.map(
            ({ name, citicode, amc, inceptionDate, disabled }: any) => ({
              name,
              citicode,
              amc,
              inceptionDate,
              disabled,
              availableReportingPeriod: getMaximumAvailableReportingPeriod(
                inceptionDate as string
              ),
            })
          )
        : [];
      dispatch(investmentFundsActions.addAllFunds(funds));
    };
    if (!availableFunds) {
      loadFunds();
    }
  }, [dispatch, availableFunds]);

  const isSynergyInvestmentBond = product === "Synergy Investment Bond";
  const isSynergyCorporateBond =
    product === "Synergy Investment Bond (Corporate)";

  return {
    availableFunds,
    investmentFundsCardDisabled,
    showLevyMessage,
    isSynergyInvestmentBond,
    isSynergyCorporateBond,
  };
};
