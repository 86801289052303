interface CheckIconProps {
  title?: string;
}

const CheckIcon = ({ title, ...otherProps }: CheckIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    {...otherProps}
  >
    {title && <title>{title}</title>}
    <path
      fill="#147CB3"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Zm11.707 5.293a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L7 10.586l5.293-5.293a1 1 0 0 1 1.414 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CheckIcon;
