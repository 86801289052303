import { SVGProps } from "react";

interface FileIconProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const FileIcon = ({ title, ...otherProps }: FileIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...otherProps}
  >
    {title && <title>{title}</title>}
    <path
      fill="#fff"
      d="M8 17a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1ZM9 12a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M6 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9.414c0-.081-.005-.162-.015-.242a1 1 0 0 0-.278-.88l-.286-.286L19.414 8 14 2.586l-.007-.007-.286-.286a1 1 0 0 0-.878-.278A2 2 0 0 0 12.586 2H6Zm6 2H6v16h12V10h-5a1 1 0 0 1-1-1V4Zm2 4V5.414L16.586 8H14Z"
      clipRule="evenodd"
    />
  </svg>
);

export default FileIcon;
