/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getFunds = /* GraphQL */ `query GetFunds {
  getFunds {
    citicode
    name
    amc
    inceptionDate
    disabled
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFundsQueryVariables, APITypes.GetFundsQuery>;
export const getUploadLogoUrl =
  /* GraphQL */ `query GetUploadLogoUrl($input: UploadLogoInput!) {
  getUploadLogoUrl(input: $input) {
    url
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetUploadLogoUrlQueryVariables,
    APITypes.GetUploadLogoUrlQuery
  >;
export const getPortfolioData =
  /* GraphQL */ `query GetPortfolioData($input: PortfolioDataInput!) {
  getPortfolioData(input: $input) {
    seriesData {
      date
      value
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetPortfolioDataQueryVariables,
    APITypes.GetPortfolioDataQuery
  >;
