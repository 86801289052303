import { useState } from "react";
import { useInvestmentDetails } from "./hooks";
import Card from "../ui/Card";
import {
  Label,
  Input,
  Select,
  DrawdownAmount,
  RadioGroup,
  RadioOption,
  RadioInput,
  ErrorMessage,
  ClientFundInput,
  ContributionWrapper,
  HelpTooltip,
  FieldWrapper,
  Checkbox,
  SinglePremiumInput,
  RegularPremiumInput,
} from "./styles";
import { useInvestmentAmount } from "components/InvestmentAmountCard/hooks";
import {
  withdrawalProducts,
  contributionProducts,
  withdrawalCappedAt5PercentProducts,
  otherProducts,
} from "helpers/products";

const InvestmentDetailsCard = ({ props }: any) => {
  const {
    clientName,
    handleClientNameChange,
    product,
    handleProductChange,
    hasDrawdown,
    handleHasDrawdownChange,
    drawdownType,
    handleDrawdownTypeChange,
    drawdown,
    drawdownMonetary,
    handleDrawdownChange,
    handleDrawdownBlur,
    handleDrawdownMonetaryAmountBlur,
    handleDrawdownMonetaryAmountChange,
    handleHasRegularPremium,
    handleHasSinglePremium,
    logTooltipAnalyticsEventWithdrawal5PercentCap,
    hasSingle,
    hasRegular,
    errors,
  } = useInvestmentDetails();

  const { clientFunds, updateClientFunds, updateRegularContribution } =
    useInvestmentAmount();

  const [contributionSinglePremiumValue, setContributionSinglePremiumValue] =
    useState<string>("");

  return (
    <Card title="Investment details" {...props}>
      <Label>Client name</Label>

      <Input
        name="client-name"
        autoComplete="off"
        value={clientName}
        onChange={(e) => handleClientNameChange(e.target.value)}
      />

      <Label>Select a product</Label>

      <Select
        name="product"
        value={product}
        onChange={(e) => handleProductChange(e.target.value)}
      >
        <option value="">Select a product...</option>
        <option value="Synergy ARF">Synergy ARF</option>
        <option value="Synergy Buy Out Bond">Synergy Buy Out Bond</option>
        <option value="Synergy Investment Bond (Corporate)">
          Synergy Investment Bond (Corporate)
        </option>
        <option value="Synergy Investment Bond">Synergy Investment Bond</option>
        <option value="Synergy Personal Pension">
          Synergy Personal Pension
        </option>
        <option value="Synergy PRSA">Synergy PRSA</option>
        <option value="Synergy PRSA (Vested)">Synergy PRSA (Vested)</option>
        <option value="Synergy Regular Invest">Synergy Regular Invest</option>
      </Select>

      {withdrawalProducts.includes(product) && (
        <>
          <Label>Investment premium (gross)</Label>

          <ClientFundInput
            prefix="&euro;"
            maxLength={8}
            decimals={0}
            value={clientFunds}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateClientFunds(e.target.value);
            }}
          />
          <Label>Annual drawdown</Label>
          <FieldWrapper>
            <RadioGroup>
              <RadioOption>
                <RadioInput
                  type="radio"
                  name="drawdown"
                  id="yes"
                  checked={hasDrawdown === true}
                  onChange={() => handleHasDrawdownChange(true)}
                />
                <Label htmlFor="yes">Yes</Label>
              </RadioOption>

              <RadioOption>
                <RadioInput
                  type="radio"
                  name="drawdown"
                  id="no"
                  checked={hasDrawdown === false}
                  onChange={() => handleHasDrawdownChange(false)}
                />
                <Label htmlFor="no">No</Label>
              </RadioOption>
            </RadioGroup>
            {withdrawalCappedAt5PercentProducts.includes(product) && (
              <HelpTooltip
                onClick={logTooltipAnalyticsEventWithdrawal5PercentCap}
              >
                Maximum regular withdrawal permitted is 5% p.a
              </HelpTooltip>
            )}
          </FieldWrapper>

          {hasDrawdown && (
            <>
              <Label>Specify drawdown type</Label>

              <RadioGroup>
                <RadioOption>
                  <RadioInput
                    type="radio"
                    name="drawdown_type"
                    id="monetary"
                    checked={drawdownType === "monetary"}
                    onChange={() => handleDrawdownTypeChange("monetary")}
                  />
                  <Label htmlFor="monetary">Monetary amount</Label>
                </RadioOption>

                <RadioOption>
                  <RadioInput
                    type="radio"
                    name="drawdown_type"
                    id="percent"
                    checked={drawdownType === "percent"}
                    onChange={() => handleDrawdownTypeChange("percent")}
                  />
                  <Label htmlFor="percent">Percent amount</Label>
                </RadioOption>
              </RadioGroup>
            </>
          )}

          {hasDrawdown && drawdownType === "percent" && (
            <>
              <Label>
                Drawdown amount{" "}
                {!withdrawalCappedAt5PercentProducts.includes(product) && (
                  <>(max 100%)</>
                )}
              </Label>

              <DrawdownAmount
                name="drawdown"
                suffix="%"
                maxLength={3}
                decimals={2}
                defaultEmptyValueToZero={true}
                value={drawdown.toString()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDrawdownChange(parseFloat(e.target.value))
                }
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  handleDrawdownBlur(parseFloat(e.target.value))
                }
                validationRules={{ min: 1, max: 100 }}
              />
              {drawdownType === "percent" &&
                errors.drawdown?.type === "max" && (
                  <ErrorMessage>
                    The maximum drawdown amount is 100%
                  </ErrorMessage>
                )}
              {drawdownType === "percent" &&
                errors.drawdown?.type === "min" && (
                  <ErrorMessage>The minimum drawdown amount is 1%</ErrorMessage>
                )}
            </>
          )}

          {hasDrawdown && drawdownType === "monetary" && (
            <>
              <Label>Drawdown amount</Label>
              <DrawdownAmount
                name="drawdown_monetary"
                prefix="€"
                decimals={2}
                defaultEmptyValueToZero={true}
                value={drawdownMonetary.toString()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDrawdownMonetaryAmountChange(parseFloat(e.target.value))
                }
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  handleDrawdownMonetaryAmountBlur(parseFloat(e.target.value))
                }
                validationRules={{ min: 1 }}
              />
              {drawdownType === "monetary" &&
                drawdownMonetary > parseFloat(clientFunds) && (
                  <ErrorMessage>
                    The maximum drawdown amount is {clientFunds}
                  </ErrorMessage>
                )}
              {drawdownType === "monetary" &&
                errors.drawdown_monetary?.type === "min" && (
                  <ErrorMessage>
                    You must enter a drawdown amount greater than 0
                  </ErrorMessage>
                )}
            </>
          )}
        </>
      )}

      {contributionProducts.includes(product) && (
        <>
          <Label>Select investment premium type(s)</Label>

          <ContributionWrapper>
            <Checkbox
              label="Single"
              id="single"
              checked={hasSingle === true}
              onChange={() => {
                handleHasSinglePremium(!hasSingle);
                updateClientFunds(
                  `${!hasSingle ? contributionSinglePremiumValue : 0}`
                );
              }}
            />

            {hasSingle && (
              <>
                <Label>Single investment premium amount</Label>

                <SinglePremiumInput
                  name="single_premium"
                  prefix="&euro;"
                  maxLength={8}
                  decimals={0}
                  defaultEmptyValueToZero={true}
                  value={clientFunds}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setContributionSinglePremiumValue(e.target.value);
                    updateClientFunds(e.target.value);
                  }}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    setContributionSinglePremiumValue(e.target.value);
                    updateClientFunds(e.target.value);
                  }}
                  validationRules={{ min: 1 }}
                />
                {errors.single_premium?.type === "min" && (
                  <ErrorMessage>
                    You must enter an amount greater than 0
                  </ErrorMessage>
                )}
              </>
            )}
            <Checkbox
              label="Regular"
              id="regular"
              checked={hasRegular === true}
              onChange={() => {
                handleHasRegularPremium(!hasRegular);
                updateRegularContribution(
                  `${!hasRegular ? drawdownMonetary * -1 : 0}`
                );
              }}
            />

            {hasRegular && (
              <>
                <Label>Regular investment premium amount per annum</Label>

                <RegularPremiumInput
                  name="regular_premium"
                  prefix="&euro;"
                  maxLength={8}
                  decimals={0}
                  value={
                    drawdownMonetary
                      ? Math.abs(drawdownMonetary).toString()
                      : undefined
                  }
                  // Negative drawdown is a cont
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateRegularContribution(
                      `${hasRegular ? parseFloat(e.target.value || "0") : 0}`
                    );
                    handleDrawdownMonetaryAmountChange(
                      -Math.abs(parseFloat(e.target.value))
                    );
                  }}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    updateRegularContribution(
                      `${hasRegular ? parseFloat(e.target.value || "0") : 0}`
                    );
                    handleDrawdownMonetaryAmountBlur(
                      -Math.abs(parseFloat(e.target.value))
                    );
                  }}
                  validationRules={{ min: 1 }}
                />
                {errors.regular_premium?.type === "min" && (
                  <ErrorMessage>
                    You must enter an amount greater than 0
                  </ErrorMessage>
                )}
              </>
            )}

            {((!hasSingle && hasRegular === false) ||
              (!hasRegular && hasSingle === false)) && (
              <ErrorMessage>
                Please select at least one premium type
              </ErrorMessage>
            )}
          </ContributionWrapper>
        </>
      )}

      {otherProducts.includes(product) && (
        <>
          <Label>Investment premium (gross)</Label>

          <ClientFundInput
            prefix="&euro;"
            maxLength={8}
            decimals={0}
            value={clientFunds}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateClientFunds(e.target.value);
            }}
          />
        </>
      )}
    </Card>
  );
};
export default InvestmentDetailsCard;
