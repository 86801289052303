import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import dayjs from "dayjs";
import { useSelector } from "app/hooks";
import { getPortfolioData } from "../../../graphql/queries";
import { GetPortfolioDataQuery } from "API";
import { getReportingPeriod } from "helpers/reportingPeriod";
import { formatCurrency } from "helpers/currency";
import {
  contributionProducts,
  otherProducts,
  withdrawalProducts,
} from "helpers/products";

interface DataPoint {
  date: string;
  value: number;
  name?: string;
}

interface ChartData {
  seriesData: Array<DataPoint>;
}

export const usePerformanceChart = () => {
  const { selectedFunds, reportingPeriod: reportingPeriodYears } = useSelector(
    (state) => state.investmentFunds
  );
  const {
    investmentAmount,
    clientFunds,
    netAllocation,
    regularContributionNet,
  } = useSelector((state) => state.investmentAmount);

  const {
    drawdownPercent,
    drawdownMonetaryAmount,
    drawdownType,
    hasDrawdown,
    hasRegular,
    hasSingle,
    product,
  } = useSelector((state) => state.investmentDetails);
  const [chartData, setChartData] = useState<ChartData | undefined>(undefined);
  const { startDate, endDate } = getReportingPeriod(
    reportingPeriodYears as number
  );
  const indefiniteArticle =
    [8, 11, 18, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89].indexOf(
      reportingPeriodYears as number
    ) !== -1
      ? "an"
      : "a";

  const investmentAmountStr =
    contributionProducts.includes(product) && !hasSingle
      ? "No single premium invested"
      : `€${formatCurrency(
          clientFunds
        )} invested with ${netAllocation}% allocation in ${dayjs(
          startDate
        ).format("MMMM YYYY")}`;
  let chartTitle = `${investmentAmountStr}`;
  let chartSubTitle = `over ${indefiniteArticle} ${reportingPeriodYears} year period`;

  if (withdrawalProducts.includes(product)) {
    if (drawdownType === "percent" && drawdownPercent > 0) {
      chartSubTitle = `${chartSubTitle} with ${drawdownPercent}% drawdown`;
    }
    if (drawdownType === "monetary" && drawdownMonetaryAmount > 0) {
      chartSubTitle = `${chartSubTitle} with a €${formatCurrency(
        drawdownMonetaryAmount
      )} drawdown`;
    }
  }

  if (contributionProducts.includes(product)) {
    // less than 0 as negative drawdown equals contribution
    if (drawdownMonetaryAmount < 0 && hasRegular) {
      chartSubTitle = `${chartSubTitle} with a €${formatCurrency(
        Math.abs(drawdownMonetaryAmount)
      )} regular premium with ${netAllocation}% allocation`;
    }
  }

  useEffect(() => {
    const loadFunds = async (): Promise<void> => {
      setChartData(undefined);
      const funds = selectedFunds
        .filter((fund) => !!fund.allocation)
        .map(({ citicode, allocation }) => ({
          citicode,
          allocation,
        }));
      const input = {
        funds,
        startDate,
        endDate,
        investmentAmount,
        drawdownPercent,
        drawdownMonetaryAmount,
        netAllocation,
      };

      let cleanedInput = { ...input };

      if (hasDrawdown && withdrawalProducts.includes(product)) {
        if (drawdownType === "percent") {
          cleanedInput = { ...input, drawdownMonetaryAmount: 0 };
        } else if (drawdownType === "monetary") {
          cleanedInput = { ...input, drawdownPercent: 0 };
        }
      }

      if (contributionProducts.includes(product)) {
        if (!hasSingle) {
          cleanedInput = { ...input, investmentAmount: 0 };
          if (hasRegular) {
            cleanedInput.drawdownMonetaryAmount = regularContributionNet * -1;
          }
        }
        if (!hasRegular) {
          cleanedInput = { ...input, drawdownMonetaryAmount: 0 };
        }
      }

      if (otherProducts.includes(product)) {
        cleanedInput = {
          ...input,
          drawdownMonetaryAmount: 0,
          drawdownPercent: 0,
        };
      }

      const { data } = await API.graphql<GraphQLQuery<GetPortfolioDataQuery>>(
        graphqlOperation(getPortfolioData, {
          input: cleanedInput,
        })
      );
      if (data?.getPortfolioData?.seriesData) {
        setChartData({ seriesData: data.getPortfolioData.seriesData });
      }
    };
    if (reportingPeriodYears && selectedFunds && investmentAmount) {
      loadFunds();
    }
  }, [
    reportingPeriodYears,
    selectedFunds,
    investmentAmount,
    startDate,
    endDate,
    drawdownPercent,
    drawdownType,
    drawdownMonetaryAmount,
    regularContributionNet,
    netAllocation,
    hasDrawdown,
    hasSingle,
    hasRegular,
    product,
  ]);

  return {
    loading: !chartData,
    chartTitle,
    chartSubTitle,
    chartData,
    reportingPeriodYears,
  };
};
