import { useRef } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";
import { transformISOToDMYDate } from "helpers/dates";
import { formatCurrency } from "helpers/currency";
import { usePerformanceChart } from "./hooks";
import { Spinner, SpinnerLabel } from "./styles";
require("highcharts/modules/accessibility")(Highcharts);

const PerformanceChart = (props: HighchartsReact.Props) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const {
    loading,
    chartTitle,
    chartSubTitle,
    chartData,
    reportingPeriodYears,
  } = usePerformanceChart();

  if (loading) {
    return (
      <div>
        <Spinner />
        <SpinnerLabel>Building performance chart</SpinnerLabel>
      </div>
    );
  }

  const categories = chartData?.seriesData.map(({ date }) => {
    return date.split("/").reverse().join("-");
  });

  const values = chartData?.seriesData.map(({ value }) => value);

  const options: Highcharts.Options = {
    chart: {
      type: "line",
      plotBackgroundColor: "#f8f9fb",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: chartTitle,
      style: { color: "#051a3f", fontSize: "1em" },
    },
    subtitle: {
      text: chartSubTitle,
      style: { color: "#051a3f", fontSize: "1em" },
    },
    accessibility: {
      description:
        "Chart showing the historic performance of the portfolio over the selected reporting period",
    },
    xAxis: {
      categories: categories,
      tickInterval: 30 * (reportingPeriodYears || 1),
      tickLength: 10,
      tickWidth: 1,
      tickColor: "#d9dbdd",
      gridLineColor: "#d9dbdd",
      gridLineWidth: 1,
      lineColor: "#d9dbdd",
      labels: {
        formatter: function () {
          return `${dayjs(this.value).format(`MMM 'YY`)}`;
        },
        style: { color: "#0a2f73" },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        formatter: function () {
          return `€${parseFloat(this.value.toString()).toLocaleString("en-UK", {
            maximumFractionDigits: 0,
          })}`;
        },
        style: { color: "#0a2f73" },
      },
      gridLineColor: "#d9dbdd",
      lineColor: "#d9dbdd",
    },
    tooltip: {
      formatter: function () {
        return (
          "The portfolio value on <b>" +
          transformISOToDMYDate(this.x as string) +
          "</b> was <b>€" +
          formatCurrency(this.y as number) +
          "</b>"
        );
      },
    },
    series: [
      {
        type: "line",
        data: values,
        color: "#0a2f73",
        name: "Client portfolio",
      },
    ],
    plotOptions: {
      series: {
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          },
        },
      },
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      {...props}
    />
  );
};

export default PerformanceChart;
