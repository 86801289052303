import * as React from "react";
import { SVGProps } from "react";

interface CustomiseReportIconProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const CustomiseReportIcon = ({
  title,
  ...otherProps
}: CustomiseReportIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    viewBox="0 0 48 48"
    {...otherProps}
  >
    {title && <title>{title}</title>}
    <path
      d="M55.293 45.293 18.707 8.707a1 1 0 0 0-1.414 0l-8.586 8.586a1 1 0 0 0 0 1.414l36.586 36.586a1 1 0 0 0 1.414 0l8.586-8.586a1 1 0 0 0 0-1.414Z"
      style={{
        fill: "#0a2f73",
        fillRule: "nonzero",
      }}
      transform="translate(-8.29 -8.414)"
    />
    <path
      d="M18.707 16.707a.999.999 0 1 0-1.414-1.414l-6 6 1.414 1.414 6-6ZM48.707 46.707a.999.999 0 1 0-1.414-1.414l-6 6 1.414 1.414 6-6ZM41.707 42.293a.999.999 0 0 1 0 1.414l-4 4-1.414-1.414 4-4a.999.999 0 0 1 1.414 0ZM21.707 23.707a.999.999 0 1 0-1.414-1.414l-4 4 1.414 1.414 4-4Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-8.29 -8.414)"
    />
    <path
      d="M9.265 52.205 13 41l7-7 10 10-7 7-11.205 3.735c-1.564.521-3.051-.966-2.53-2.53Z"
      style={{
        fill: "#fee0c1",
        fillRule: "nonzero",
      }}
      transform="translate(-8.29 -8.414)"
    />
    <path
      d="m10 50-1.684 5.051a.5.5 0 0 0 .633.633L14 54l-4-4Z"
      style={{
        fill: "#0a2f73",
        fillRule: "nonzero",
      }}
      transform="translate(-8.29 -8.414)"
    />
    <path
      d="M13 41 45.293 8.707a1 1 0 0 1 1.414 0l8.586 8.586a1 1 0 0 1 0 1.414L23 51V41H13Z"
      style={{
        fill: "#fb0",
        fillRule: "nonzero",
      }}
      transform="translate(-8.29 -8.414)"
    />
    <path
      d="m19 41 30-30 4 4-30 30h-4v-4Z"
      style={{
        fill: "#0a2f73",
        fillRule: "nonzero",
      }}
      transform="translate(-8.29 -8.414)"
    />
  </svg>
);
export default CustomiseReportIcon;
