import NumericInputGroup from "components/forms/NumericInputGroup";
import DeleteIcon from "components/icons/DeleteIcon";
import { breakpointUp } from "helpers/breakpoints";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: block;
  position: relative;
  margin-top: 20px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};

  padding-bottom: 80px;

  ${breakpointUp.sm`
    display: flex;
    border-bottom: none;
    padding-bottom: 0;
    margin-top: 0;
  `}
`;

export const FundDetails = styled.div`
  display: block;
  padding-right: 30px;

  ${breakpointUp.sm`
      display: flex;
      flex: 1 0 450px;
      border-radius: 4px;
      font-size: 16px;
      line-height: 24px;
      color: #051a3f;
      border: 2px solid #a8aab1;
      min-height: 40px;
      padding-right: 0;
  `}

  ${breakpointUp.md`
        flex: 1 0 370px;
    `}

  ${breakpointUp.lg`
        flex: 1 0 450px;
    `}
`;

export const FundNumber = styled.span`
  display: none;
  flex: 0 1 30px;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.bodyText};
  background-color: ${({ theme }) => theme.colors.paleGrey};
  text-align: center;
  white-space: nowrap;
  border-right: 2px solid ${({ theme }) => theme.colors.fieldBorder};

  ${breakpointUp.sm`
    display: flex;
  `}
`;

export const FundName = styled.p`
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.bodyText};

  ${breakpointUp.sm`
    display: flex;
    flex: 1;
    align-items: center;
    margin: 0;
    padding: 0 10px;
    font-size: 16px;
    font-weight: normal;
    color: inherit;
  `}
`;

export const FundAMC = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.bodyText};
  background-color: ${({ theme }) => theme.colors.paleGrey};
  border-radius: 5px;

  position: absolute;
  bottom: 15px;
  left: 0;
  height: 38px;
  margin: 0;

  :before {
    content: "AMC";
    position: absolute;
    top: -25px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.bodyText};
    font-weight: bold;
    width: 80px;
  }

  ${breakpointUp.sm`
    position: static;
    height: auto;
    margin-left: 8px;

    :before {
      content: " ";
      display: none;
    }
  `}
`;

export const FundAllocation = styled(NumericInputGroup)`
  margin-left: 8px;
  max-width: 120px;

  position: absolute;
  bottom: 15px;
  right: 0;

  :before {
    content: "Allocation";
    position: absolute;
    top: -25px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.bodyText};
    font-weight: bold;
    width: 80px;
  }

  ${breakpointUp.sm`
    position: relative;
    bottom: auto;
    right: auto;

    :before {
      content: " ";
      display: none;
    }
  `}

  > input[type="text"] {
    height: 100%;
    padding-left: 6px;
    padding-right: 50px;
    text-align: center;
  }

  > span {
    height: 100%;
  }
`;

export const RemoveFundButton = styled.button`
  outline: none;
  background: transparent;
  margin-left: 16px;
  position: absolute;
  top: 3px;
  right: 5px;

  ${breakpointUp.sm`
    position: initial;
  `}
`;

export const RemoveFundIcon = styled(DeleteIcon)``;
