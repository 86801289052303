import { useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// import TagManager from "react-gtm-module";
import ReportDetails from "components/ReportDetails/ReportDetails";
import ReportingPeriod from "components/ReportingPeriod/ReportingPeriod";
import CustomiseReport from "components/CustomiseReport/CustomiseReport";
// import { analyticsCookiesEnabled } from "services/analytics";

// const { REACT_APP_GTM_API_KEY } = process.env;

export const event = "event";
export const eventCategory = "eventCategory";
export const eventAction = "eventAction";
export const eventLabel = "eventLabel";

function App() {
  const location = useLocation();

  // Scroll to top of page if route changes
  useLayoutEffect(() => {
    const scrollBehaviour = document.documentElement.style.scrollBehavior;
    document.documentElement.style.scrollBehavior = "auto";
    window.scrollTo(0, 0);
    document.documentElement.style.scrollBehavior = scrollBehaviour;
  }, [location.pathname]);

  // useEffect(() => {
  //   if (analyticsCookiesEnabled() && REACT_APP_GTM_API_KEY) {
  //     TagManager.initialize({
  //       gtmId: REACT_APP_GTM_API_KEY,
  //       dataLayerName: "dataLayer",
  //       events: {
  //         event,
  //         eventCategory,
  //         eventAction,
  //         eventLabel,
  //       },
  //     });
  //   }
  // }, []);

  return (
    <Routes>
      <Route path="/" element={<ReportDetails />} />
      <Route path="/reportingperiod" element={<ReportingPeriod />} />
      <Route path="/customisereport" element={<CustomiseReport />} />
    </Routes>
  );
}

export default App;
