import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  regularContribution: string;
  clientFunds: string;
  netAllocation: string;
  investmentAmount: number;
  regularContributionNet: number;
}

const initialState: InitialState = {
  regularContribution: "",
  regularContributionNet: 0,
  clientFunds: "0",
  netAllocation: "",
  investmentAmount: 0,
};

const safelyCalculateInvestmentAmount = (
  amount: number,
  netAllocation: number
) => {
  return (amount * netAllocation) / 100;
};

const investmentAmountSlice = createSlice({
  name: "investmentAmount",
  initialState,
  reducers: {
    updateRegularContribution: (state, action: PayloadAction<string>) => {
      state.regularContribution = action.payload;
      state.investmentAmount = safelyCalculateInvestmentAmount(
        parseFloat(state.regularContribution || "0") +
          parseFloat(state.clientFunds || "0"),
        parseFloat(state.netAllocation || "0")
      );
      state.regularContributionNet = safelyCalculateInvestmentAmount(
        parseFloat(state.regularContribution || "0"),
        parseFloat(state.netAllocation || "0")
      );
    },
    updateClientFunds: (state, action: PayloadAction<string>) => {
      state.clientFunds = action.payload;
      state.investmentAmount = safelyCalculateInvestmentAmount(
        parseFloat(state.regularContribution || "0") +
          parseFloat(state.clientFunds || "0"),
        parseFloat(state.netAllocation || "0")
      );
      state.regularContributionNet = safelyCalculateInvestmentAmount(
        parseFloat(state.regularContribution || "0"),
        parseFloat(state.netAllocation || "0")
      );
    },
    updateNetAllocation: (state, action: PayloadAction<string>) => {
      state.netAllocation = action.payload;
      state.investmentAmount = safelyCalculateInvestmentAmount(
        parseFloat(state.regularContribution || "0") +
          parseFloat(state.clientFunds || "0"),
        parseFloat(state.netAllocation || "0")
      );
      state.regularContributionNet = safelyCalculateInvestmentAmount(
        parseFloat(state.regularContribution || "0"),
        parseFloat(state.netAllocation || "0")
      );
    },
    reset: (state) => {
      state.regularContribution = initialState.regularContribution;
      state.regularContributionNet = initialState.regularContributionNet;
      state.clientFunds = initialState.clientFunds;
      state.netAllocation = initialState.netAllocation;
      state.investmentAmount = initialState.investmentAmount;
    },
  },
});

export default investmentAmountSlice.reducer;
export const investmentAmountActions = investmentAmountSlice.actions;
