import uiHelpTooltip from "components/ui/HelpTooltip/HelpTooltip";
import { H3, P } from "components/ui/Typography";
import { breakpointUp } from "helpers/breakpoints";
import styled from "styled-components";
import NumericInputGroup from "../forms/NumericInputGroup";

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 8px;
  padding: 0 32px 32px 32px;
  margin-bottom: 16px;
`;

export const CardTitle = styled(H3)`
  margin: 0 -32px;
  padding: 10px 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  font-size: 20px;
`;

export const CardContent = styled.div`
  width: 100%;

  ${breakpointUp.lg`
        max-width: 580px;
    `}
`;

export const FieldWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 8px;
`;

export const ClientFundFieldWrapper = styled(FieldWrapper)`
  align-items: center;

  ${breakpointUp.sm`
    align-items: initial;
  `}

  > label {
    height: 74px;

    ${breakpointUp.sm`
      height: auto;
    `}
  }

  > div {
    max-height: 44px;
  }
`;

export const InlineLabel = styled.label`
  display: flex;
  flex: 1;
  height: 44px;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 0px 10px;
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: ${({ theme }) => theme.colors.paleGrey};
  border-radius: 5px;
`;

export const ClientFundInput = styled(NumericInputGroup)`
  display: flex;
  flex: 0 0 160px;
  margin-left: 8px;
`;

export const NetAllocationInput = styled(NumericInputGroup)`
  display: flex;
  flex: 0 0 160px;
  margin-left: 8px;
`;

export const Summary = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  padding: 0 10px;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.bodyText};
  background-color: ${({ theme }) => theme.colors.paleGrey};
  border-radius: 5px;

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }
  &.normal {
    p {
      font-weight: normal;
    }
  }
`;

export const Label = styled.p`
  margin: 0;
  flex: 1;
`;

export const Total = styled.p`
  margin: 0;
`;

export const NetAllocationInfo = styled.p`
  margin: 10px 0 0 0;
  font-size: 14px;
`;

export const HelpTooltip = styled(uiHelpTooltip)`
  position: absolute;
  right: -37px;
  margin: 12px;
  max-width: 400px;

  ${breakpointUp.lg`
    right: -40px;
  `}
`;

export const ErrorMessage = styled(P)`
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.error};
`;
