import styled from "styled-components";
import { H2 } from "components/ui/Typography";

const getIndicationColor = ({
  theme,
  state,
}: {
  theme: any;
  state: string;
}) => {
  switch (state) {
    case "previous":
      return theme.colors.previousStep;
    case "current":
      return theme.colors.currentStep;
    default:
      return theme.colors.nextStep;
  }
};

export const Wrapper = styled.div`
  margin-bottom: 16px;
`;

export const SectionNumber = styled.p`
  font-size: 14px;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const Title = styled(H2)`
  margin: 0 0 12px 0;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const SectionIndicator = styled(({ state, children, ...otherProps }) => (
  <div {...otherProps} />
))`
  width: 62px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ theme, state }) =>
    getIndicationColor({ theme, state })};
  margin-right: 8px;
`;
