import DeleteIcon from "./DeleteIcon";
import { useImageUpload } from "./hooks";
import {
  DeleteButton,
  FileUpload,
  FileUploadIcon,
  Image,
  ImagePreview,
  ImageScaling,
  ScalingHelpText,
  Separator,
  Slider,
  SliderLabel,
  UploadButton,
  UploadMessage,
  Wrapper,
} from "./styles";

interface ImageUploaderProps {
  onChange?: Function;
  onScaleChange?: Function;
  id?: string;
  imageUploadHelpText?: string;
  scalingHelpText?: string;
  maximumFileSize?: number;
  resizedImageSize?: number;
  imageDataURL?: string | undefined;
  imageScale?: number;
}

const ImageUploader = ({
  onChange,
  onScaleChange,
  id,
  imageUploadHelpText = "Drag a JPG, PNG or SVG under 5mb here to upload your logo.",
  scalingHelpText,
  maximumFileSize,
  resizedImageSize,
  imageDataURL,
  imageScale,
  ...otherProps
}: ImageUploaderProps) => {
  const {
    sliderValue,
    handleSliderChange,
    handleSliderAfterChange,
    dataURL,
    getRootProps,
    getInputProps,
    resetImageUpload,
    handleBrowseFilesButtonClick,
  } = useImageUpload({
    onChange,
    onScaleChange,
    maximumFileSize,
    resizedImageSize,
    imageDataURL,
    imageScale,
  });

  return (
    <Wrapper {...otherProps}>
      {dataURL ? (
        <div className="image-preview-wrapper">
          <ImagePreview className="image-preview">
            <DeleteButton
              type="button"
              title="Remove logo"
              onClick={resetImageUpload}
            >
              <DeleteIcon />
            </DeleteButton>
            <Image
              alt="Logo"
              src={dataURL}
              style={{ width: `${50 * (sliderValue / 100)}%` }}
            />
          </ImagePreview>

          <ImageScaling className="image-scaling">
            <ScalingHelpText className="scaling-help-text">
              {scalingHelpText}
            </ScalingHelpText>

            <SliderLabel>
              Logo scale <strong>{sliderValue}%</strong>
            </SliderLabel>

            <Slider
              value={sliderValue}
              min={50}
              max={150}
              className="horizontal-slider"
              thumbClassName="slider-thumb"
              trackClassName="slider-track"
              onChange={(value) => handleSliderChange(value as number)}
              onAfterChange={(value) =>
                handleSliderAfterChange(value as number)
              }
            />
          </ImageScaling>
        </div>
      ) : (
        <FileUpload className="file-upload-wrapper" {...getRootProps()}>
          <input id={id} {...getInputProps()} />

          <FileUploadIcon />

          <UploadMessage>{imageUploadHelpText}</UploadMessage>

          <Separator>OR</Separator>

          <UploadButton secondary onClick={handleBrowseFilesButtonClick}>
            Browse files
          </UploadButton>
        </FileUpload>
      )}
    </Wrapper>
  );
};

export default ImageUploader;
