import { useFormContext } from "react-hook-form";
import { investmentAmountActions } from "app/features/investmentAmount/investmentAmountSlice";
import { useDispatch, useSelector } from "app/hooks";
import { logAnalyticsEvent } from "services/analytics";
import { withdrawalProducts } from "helpers/products";

export const useInvestmentAmount = () => {
  const dispatch = useDispatch();
  const { clientName, product, hasDrawdown, drawdownPercent } = useSelector(
    (state) => state.investmentDetails
  );
  const { clientFunds, netAllocation, investmentAmount, regularContribution } =
    useSelector((state) => state.investmentAmount);
  const { totalAllocation } = useSelector((state) => state.investmentFunds);
  const {
    formState: { errors },
  } = useFormContext();

  const investmentAmountCardDisabled =
    !clientName ||
    !product ||
    (withdrawalProducts.includes(product) && hasDrawdown === undefined) ||
    drawdownPercent > 100 ||
    totalAllocation !== 100;

  const updateRegularContribution = (value: string) => {
    dispatch(investmentAmountActions.updateRegularContribution(value));
  };

  const updateClientFunds = (value: string) => {
    dispatch(investmentAmountActions.updateClientFunds(value));
  };

  const updateNetAllocation = (value: string) => {
    dispatch(investmentAmountActions.updateNetAllocation(value));
  };

  const isInvestmentOrBondProduct =
    ["Synergy Investment Bond", "Synergy Investment Bond (Corporate)"].indexOf(
      product
    ) !== -1;

  const isPersonalPensionProduct =
    ["Synergy Personal Pension"].indexOf(product) !== -1;

  const logTooltipAnalyticsEvent = () => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 1",
      label: "Net Allocation tooltip",
    });
  };

  return {
    clientFunds,
    regularContribution,
    updateClientFunds,
    updateRegularContribution,
    netAllocation,
    updateNetAllocation,
    isPersonalPensionProduct,
    investmentAmount,
    isInvestmentOrBondProduct,
    investmentAmountCardDisabled,
    errors,
    logTooltipAnalyticsEvent,
  };
};
