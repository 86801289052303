import { Wrapper } from "./styles";

interface SpinnerProps {
  title?: string;
}

const Spinner = ({ ...otherProps }: SpinnerProps) => (
  <Wrapper {...otherProps}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      viewBox="0 0 213 212"
    >
      <path
        d="M14 100H-6C-6 41.85 41.85-6 100-6s106 47.85 106 106h-20c0-47.178-38.822-86-86-86s-86 38.822-86 86Z"
        style={{
          fill: "url(#gradient1)",
        }}
        transform="translate(6.005 6)"
      />
      <path
        d="M186 100h20c0 58.15-47.85 106-106 106S-6 158.15-6 100h20c0 47.178 38.822 86 86 86s86-38.822 86-86Z"
        style={{
          fill: "url(#gradient2)",
        }}
        transform="translate(6.005 6)"
      />
      <path
        d="M13.999 99.895c.058 5.519-4.375 10.046-9.894 10.104-5.519.058-10.046-4.375-10.104-9.894-.008-.737-.008-1.473 0-2.21.058-5.519 4.585-9.952 10.104-9.894 5.519.058 9.952 4.585 9.894 10.104a89.005 89.005 0 0 0 0 1.79Z"
        style={{
          fill: "currentColor",
        }}
        transform="translate(6.005 6)"
      />
      <defs>
        <linearGradient
          id="gradient1"
          x1={0}
          x2={1}
          y1={0}
          y2={0}
          gradientTransform="matrix(192 0 0 192 4 4)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={0}
            style={{
              stopColor: "currentColor",
              stopOpacity: 0.01,
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "currentColor",
              stopOpacity: 0.5,
            }}
          />
        </linearGradient>
        <linearGradient
          id="gradient2"
          x1={0}
          x2={1}
          y1={0}
          y2={0}
          gradientTransform="matrix(192 0 0 192 4 100)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={0}
            style={{
              stopColor: "currentColor",
              stopOpacity: 1,
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "currentColor",
              stopOpacity: 0.5,
            }}
          />
        </linearGradient>
      </defs>
    </svg>
  </Wrapper>
);
export default Spinner;
