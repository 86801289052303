import { Fund } from "../../../../types/types";
import {
  FundAllocation,
  FundAMC,
  FundDetails,
  FundName,
  FundNumber,
  RemoveFundButton,
  RemoveFundIcon,
  Wrapper,
} from "./styles";

interface SelectedFundProps {
  fundNumber: number;
  fund: Fund;
  onAllocationChange: Function;
  onRemoveFund: Function;
}

const SelectedFund = ({
  fund,
  fundNumber,
  onAllocationChange,
  onRemoveFund,
}: SelectedFundProps) => (
  <Wrapper>
    <FundDetails>
      <FundNumber>{fundNumber}</FundNumber>
      <FundName>{fund.name}</FundName>
    </FundDetails>
    <FundAMC>{fund.amc}</FundAMC>
    <FundAllocation
      id="fund-allocation"
      suffix="%"
      maxLength={6}
      decimals={2}
      value={fund.allocation === undefined ? "" : fund.allocation.toString()}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedFund: Fund = {
          ...fund,
          allocation: isNaN(parseFloat(e.target.value))
            ? undefined
            : parseFloat(e.target.value),
        };
        onAllocationChange(updatedFund);
      }}
    />
    <RemoveFundButton
      type="button"
      aria-label="Remove fund"
      onClick={() => onRemoveFund(fund)}
      tabIndex={-1}
    >
      <RemoveFundIcon title="Remove fund" />
    </RemoveFundButton>
  </Wrapper>
);

export default SelectedFund;
