import dayjs from "dayjs";
import { Fund } from "types/types";
import { useReportingPeriod } from "./hooks";
import {
  Card,
  DesktopFundsList,
  DropdownWrapper,
  FundCard,
  FundName,
  HelpTooltip,
  InceptionDate,
  Label,
  MobileFundsList,
  ReportingPeriod,
  Select,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "./styles";

type DropdownOptions = {
  label: string;
  value: string;
};

const formatReportingPeriod = (value: number): string =>
  value === 1 ? `${value} year` : `${value} years`;

const getReportingPeriodOptions = (value: number): Array<DropdownOptions> => {
  const dropdownOptions: Array<DropdownOptions> = [];
  for (let i = value; i >= 1; i--) {
    dropdownOptions.push({
      label: `${formatReportingPeriod(i)}${i === value ? " (max)" : ""}`,
      value: i.toString(),
    });
  }
  return dropdownOptions;
};

function ReportingPeriodCard() {
  const {
    selectedFunds,
    maximumReportingPeriod,
    reportingPeriod,
    handleReportingPeriodChange,
    handleTooltip,
  } = useReportingPeriod();

  const funds = selectedFunds.filter((fund) => !!fund.allocation);

  return (
    <Card>
      <DesktopFundsList>
        <Table>
          <THead>
            <Tr>
              <Th>Investment fund</Th>

              <Th style={{ width: "170px" }}>Fund inception</Th>

              <Th style={{ width: "170px" }}>Available data</Th>
            </Tr>
          </THead>

          <TBody>
            {funds.map(
              ({ name, inceptionDate, availableReportingPeriod }: Fund) => (
                <Tr key={name}>
                  <Td>{name}</Td>
                  <Td>{dayjs(inceptionDate).format("MMM YYYY")}</Td>
                  <Td>{formatReportingPeriod(availableReportingPeriod)}</Td>
                </Tr>
              )
            )}
          </TBody>
        </Table>
      </DesktopFundsList>

      <MobileFundsList>
        {funds.map(
          ({ name, inceptionDate, availableReportingPeriod }: Fund) => (
            <FundCard key={name}>
              <FundName>{name}</FundName>
              <InceptionDate>
                <strong>Inception date:</strong>{" "}
                {dayjs(inceptionDate).format("MMM YYYY")}
              </InceptionDate>
              <ReportingPeriod>
                <strong>Reporting period:</strong>{" "}
                {formatReportingPeriod(availableReportingPeriod)}
              </ReportingPeriod>
            </FundCard>
          )
        )}
      </MobileFundsList>

      <Label htmlFor="reportingPeriod">Select the reporting period below</Label>

      <DropdownWrapper>
        <Select
          id="reportingPeriod"
          value={reportingPeriod}
          onChange={(e) =>
            handleReportingPeriodChange(parseInt(e.target.value))
          }
          placeholder="Select a period..."
        >
          {getReportingPeriodOptions(maximumReportingPeriod).map(
            ({ label, value }) => {
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              );
            }
          )}
        </Select>

        <HelpTooltip onClick={handleTooltip}>
          The fund with the shortest available data period in your portfolio
          dictates the maximum reporting period available.
        </HelpTooltip>
      </DropdownWrapper>
    </Card>
  );
}

export default ReportingPeriodCard;
