/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onGenerateReportPDFRequest = /* GraphQL */ `
  subscription OnGenerateReportPDFRequest($ID: ID!) {
    onGenerateReportPDFRequest(ID: $ID) {
      ID
      Status
      url
    }
  }
`;
