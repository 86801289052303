import {
  LabelCol,
  SwitchButton,
  ToggleCol,
  ToggleSwitch,
  Wrapper,
} from "./styles";
import { useSectionListItem } from "./hooks";

interface SectionListItemProps {
  label: string;
  sectionKey: string;
}

const SectionListItem = ({
  label,
  sectionKey,
  ...otherProps
}: SectionListItemProps) => {
  const { value, handleOnChange } = useSectionListItem(sectionKey, label);

  return (
    <Wrapper {...otherProps}>
      <LabelCol>{label}</LabelCol>

      <ToggleCol>
        <ToggleSwitch
          aria-label={`Enable section ${label}`}
          enabled={value}
          checked={value}
          onChange={handleOnChange}
        >
          <SwitchButton enabled={value} />
        </ToggleSwitch>
      </ToggleCol>
    </Wrapper>
  );
};

export default SectionListItem;
