import { Col, Container, Row } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { useReportingPeriodScreen } from "./hooks";
import ReportingPeriodCard from "../ReportingPeriodCard/ReportingPeriodCard";
import PerformancePreviewCard from "../PerformancePreviewCard/PerformancePreviewCard";
import { Button, Link, NavigationFooter } from "./styles";
import Header from "../Header";
import ProgressStep from "components/ProgressStep/ProgressStep";
import Footer from "components/Footer";

function ReportingPeriod() {
  const {
    redirectHome,
    continueUrl,
    continuteButtonDisabled,
    handleBack,
    handleContinue,
  } = useReportingPeriodScreen();

  if (redirectHome) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col>
            <ProgressStep
              title="Reporting period and performance review"
              currentStep={2}
              totalSteps={3}
            />
          </Col>
        </Row>

        <ReportingPeriodCard />

        <PerformancePreviewCard />

        <NavigationFooter>
          <Link to="/">
            <Button secondary onClick={handleBack}>
              Back
            </Button>
          </Link>
          <Link to={continueUrl}>
            <Button disabled={continuteButtonDisabled} onClick={handleContinue}>
              Continue
            </Button>
          </Link>
        </NavigationFooter>
      </Container>

      <Footer />
    </>
  );
}

export default ReportingPeriod;
