import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PersonaliseCard from "components/PersonaliseCard/PersonaliseCard";
import {
  Button,
  Checkbox,
  DownloadButton,
  LeftNav,
  Link,
  NavigationFooter,
  RightNav,
  StartNewReportButton,
} from "./styles";
import { Navigate } from "react-router-dom";
import { useCustomiseReportScreen } from "./hooks";
import ReportSectionsCard from "components/ReportSectionsCard/ReportSections";
import Header from "components/Header";
import ProgressStep from "components/ProgressStep/ProgressStep";
import Footer from "components/Footer";

function CustomiseReport() {
  const {
    redirectHome,
    handleStartNewReport,
    rememberCustomisationOptions,
    updateRememberCustomisationOptions,
    handleBack,
  } = useCustomiseReportScreen();
  if (redirectHome) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col>
            <ProgressStep
              title="Customise your report"
              currentStep={3}
              totalSteps={3}
            />
          </Col>
        </Row>

        <ReportSectionsCard />

        <PersonaliseCard />

        <NavigationFooter>
          <LeftNav>
            <Link to="/reportingperiod">
              <Button secondary onClick={handleBack}>
                Back
              </Button>
            </Link>
            <StartNewReportButton secondary onClick={handleStartNewReport}>
              Start new report
            </StartNewReportButton>
          </LeftNav>
          <RightNav>
            <DownloadButton />
            <Checkbox
              id="storeOptions"
              label="Remember these details for future use"
              checked={rememberCustomisationOptions}
              onChange={() =>
                updateRememberCustomisationOptions(
                  !rememberCustomisationOptions
                )
              }
            />
          </RightNav>
        </NavigationFooter>
      </Container>

      <Footer />
    </>
  );
}

export default CustomiseReport;
