import uiHelpTooltip from "components/ui/HelpTooltip/HelpTooltip";
import { breakpointUp } from "helpers/breakpoints";
import styled from "styled-components";

export const Card = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 8px;
  padding: 32px;
  margin-bottom: 16px;
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 30px;
`;

export const Tr = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`;

export const Th = styled.th`
  padding: 10px;
`;

export const Td = styled.td`
  padding: 10px;
`;

export const THead = styled.thead`
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.cardTitle};

  ${Tr} {
    height: 54px;

    ${Th} {
      border-top: 1px solid ${({ theme }) => theme.colors.cardBorder};
      border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
      background-color: ${({ theme }) => theme.colors.paleGrey};

      &:first-child {
        border-top-left-radius: 8px;
        border-left: 1px solid ${({ theme }) => theme.colors.cardBorder};
        padding-left: 30px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-right: 1px solid ${({ theme }) => theme.colors.cardBorder};
        padding-right: 30px;
      }
    }
  }
`;

export const TBody = styled.thead`
  color: ${({ theme }) => theme.colors.bodyText};

  ${Tr} {
    height: 50px;

    ${Td} {
      border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};

      &:first-child {
        border-left: 1px solid ${({ theme }) => theme.colors.cardBorder};
        padding-left: 30px;
      }

      &:last-child {
        border-right: 1px solid ${({ theme }) => theme.colors.cardBorder};
        padding-right: 30px;
      }
    }

    &:last-child {
      ${Td} {
        &:first-child {
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
`;

export const Label = styled.label`
  display: block;
  font-size: 16px;
  border-bottom: 8px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.bodyText};
`;

export const Select = styled.select`
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.colors.fieldBorder};
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  max-width: 370px;
  color: ${({ theme }) => theme.colors.bodyText};
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.29289 6.70711C5.68342 7.09763 6.31658 7.09763 6.70711 6.70711L11.7071 1.70711C12.0976 1.31658 12.0976 0.683418 11.7071 0.292894C11.3166 -0.0976306 10.6834 -0.0976306 10.2929 0.292894L6 4.58579L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893Z' fill='%23051A3F'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;

  &::placeholder {
    color: ${({ theme }) => theme.colors.paleText};
  }
`;

export const HelpTooltip = styled(uiHelpTooltip)`
  position: absolute;
  right: -25px;
  top: 12px;

  ${breakpointUp.sm`
    position: relative;
    top: 4px;
    left: 10px;
    right: auto;
  `}
`;

export const DropdownWrapper = styled.div`
  position: relative;
`;

export const DesktopFundsList = styled.div`
  display: none;

  ${breakpointUp.sm`
    display: block;
  `}
`;

export const MobileFundsList = styled.div`
  display: block;
  margin-top: -10px;
  margin-bottom: 20px;

  ${breakpointUp.sm`
    display: none;
  `}
`;

export const FundCard = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`;

export const FundName = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primaryText};
  font-weight: bold;
  margin-bottom: 5px;
`;

export const InceptionDate = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: 14px;

  strong {
    font-weight: bold;
  }
`;

export const ReportingPeriod = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: 14px;

  strong {
    font-weight: bold;
  }
`;
