/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const generateReportPDFRequest =
  /* GraphQL */ `mutation GenerateReportPDFRequest($input: ReportResultPDFInput) {
  generateReportPDFRequest(input: $input) {
    ID
    Status
    __typename
  }
}
` as GeneratedMutation<
    APITypes.GenerateReportPDFRequestMutationVariables,
    APITypes.GenerateReportPDFRequestMutation
  >;
export const publishReportResultPDF =
  /* GraphQL */ `mutation PublishReportResultPDF($result: ReportResultPDFRequestInput) {
  publishReportResultPDF(result: $result) {
    ID
    Status
    url
    __typename
  }
}
` as GeneratedMutation<
    APITypes.PublishReportResultPDFMutationVariables,
    APITypes.PublishReportResultPDFMutation
  >;
