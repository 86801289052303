import { SVGProps } from "react";

interface DownloadIconProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const DownloadIcon = ({ title, ...otherProps }: DownloadIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...otherProps}
  >
    {title && <title>{title}</title>}
    <path
      fill="#116998"
      d="M19 20a1 1 0 1 0 0-2H5a1 1 0 1 0 0 2h14ZM12.707 15.707a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L11 12.586V5a1 1 0 1 1 2 0v7.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-4 4Z"
    />
  </svg>
);

export default DownloadIcon;
