import styled from "styled-components";
import { Link as uiLink } from "react-router-dom";
import uiButton from "components/ui/Button";
import { breakpointUp } from "helpers/breakpoints";

export const NavigationFooter = styled.div`
  display: flex;
  justify-content: end;
  margin: 32px 0 50px 0;
`;

export const Link = styled(uiLink)`
  width: 100%;

  :hover {
    text-decoration: none;
  }

  ${breakpointUp.md`
    width: auto;
  `}
`;

export const Button = styled(uiButton)`
  display: block;
  width: 100%;

  ${breakpointUp.md`
    width: auto;
  `}
`;
