import * as React from "react";
import { SVGProps } from "react";

interface CloseIconProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const CloseIcon = ({ title, ...otherProps }: CloseIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={165}
    height={170}
    viewBox="0 0 165 170"
    {...otherProps}
  >
    {title && <title>{title}</title>}
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.867}
    >
      <path
        strokeWidth={22.80880294}
        d="m12.066 12.353 70.551 74.26M153.536 12.064 82.984 86.32M12.065 158.13l70.552-74.254M153.536 158.41 82.97 84.158"
      />
    </g>
  </svg>
);
export default CloseIcon;
