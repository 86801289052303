import React from "react";
import styled from "styled-components";
import NumericInput from "../NumericInput";

interface FormGroupDivProps extends React.HTMLAttributes<HTMLDivElement> {
  prefix?: string;
  suffix?: string;
}

const FormGroupDiv = ({ children, ...otherProps }: FormGroupDivProps) => (
  <div {...otherProps}>{children}</div>
);

export const Symbol = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-right: 2px;
  font-size: 20px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.bodyText};
  background-color: ${({ theme }) => theme.colors.paleGrey};
  text-align: center;
  white-space: nowrap;
  height: 44px;
  user-select: none;
`;

export const Input = styled(NumericInput)`
  padding: 8px 60px 8px 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  border: none;
  position: relative;
  width: 100%;
  min-width: 0;
  background-color: transparent;
  z-index: 1;
  border: 2px solid ${({ theme }) => theme.colors.fieldBorder};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.bodyText};

  :disabled {
    background-color: ${({ theme }) => theme.colors.paleGrey} !important;
    z-index: 0;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.bodyText};
    -webkit-opacity: 1;
  }
  :disabled + span {
    background-color: transparent;
  }
`;

export const FormGroup = styled(FormGroupDiv)`
  position: relative;

  > ${Input} {
    padding-left: ${({ prefix }) => (!!prefix ? "58px" : "16px")};
    padding-right: ${({ suffix }) => (!!suffix ? "58px" : "16px")};
  }

  > ${Symbol} {
    left: ${({ prefix }) => (!!prefix ? "0" : "initial")};
    right: ${({ suffix }) => (!!suffix ? "0" : "initial")};
    border-left: ${({ theme, suffix }) =>
      !!suffix ? `2px solid ${theme.colors.fieldBorder}` : "none"};
    border-right: ${({ theme, prefix }) =>
      !!prefix ? `2px solid ${theme.colors.fieldBorder}` : "none"};
  }
`;
