import { useIntroductionModal } from "./hooks";
import {
  Backdrop,
  ModalButton,
  Dialog,
  Panel,
  PanelWrapper,
  Title,
  FeatureCard,
  FeatureHeading,
  IconWrapper,
  Description,
  ModalContent,
  ModalNav,
  DownloadIcon,
  DownloadLink,
} from "./styles";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import "./ModalAnimations.css";
import { P } from "components/ui/Typography";
import CreateReportIcon from "components/icons/CreateReportIcon";
import CustomiseReportIcon from "components/icons/CustomiseReportIcon";

const IntroductionModal = () => {
  const { isModalOpen, closeModal, handleViewReport } = useIntroductionModal();

  return (
    <>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog open={isModalOpen} onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="backdrop-enter"
            enterFrom="backdrop-enter-from"
            enterTo="backdrop-enter-to"
            leave="backdrop-leave"
            leaveFrom="backdrop-leave-from"
            leaveTo="backdrop-leave-to"
          >
            <Backdrop />
          </Transition.Child>

          <PanelWrapper>
            <Transition.Child
              as={Fragment}
              enter="panel-enter"
              enterFrom="panel-enter-from"
              enterTo="panel-enter-to"
              leave="panel-leave"
              leaveFrom="panel-leave-from"
              leaveTo="panel-leave-to"
            >
              <Panel>
                <Title>Welcome to your new investment proposal tool</Title>

                <ModalContent>
                  <FeatureCard>
                    <IconWrapper>
                      <CreateReportIcon title="Create report icon" />
                    </IconWrapper>

                    <Description>
                      <FeatureHeading>
                        Create detailed reports within minutes
                      </FeatureHeading>

                      <P>
                        Create comprehensive and engaging client investment
                        reports in just minutes! The report features a detailed
                        fund breakdown, management and investment charges, ESG
                        summaries, historical fund performance, and more.
                      </P>
                    </Description>
                  </FeatureCard>

                  <FeatureCard>
                    <IconWrapper>
                      <CustomiseReportIcon title="Customise report icon" />
                    </IconWrapper>

                    <Description>
                      <FeatureHeading>Customise your reports</FeatureHeading>

                      <P>
                        Want to go one step further? Enhance your professional
                        presence by customising the report to include your
                        firm's information and logo, as well as personalised
                        welcome notes and supplementary text.
                      </P>
                    </Description>
                  </FeatureCard>
                </ModalContent>

                <ModalNav>
                  <DownloadLink
                    href="/IPTSampleReport190624.pdf"
                    rel="noreferrer"
                    target="_blank"
                    tabIndex={-1}
                  >
                    <ModalButton
                      type="button"
                      tabIndex={-1}
                      secondary
                      onClick={handleViewReport}
                    >
                      Download example report <DownloadIcon />
                    </ModalButton>
                  </DownloadLink>

                  <ModalButton type="button" tabIndex={-1} onClick={closeModal}>
                    Start now
                  </ModalButton>
                </ModalNav>
              </Panel>
            </Transition.Child>
          </PanelWrapper>
        </Dialog>
      </Transition>
    </>
  );
};

export default IntroductionModal;
