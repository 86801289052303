import React from "react";
import styled from "styled-components";
import { Combobox } from "@headlessui/react";
import uiSpinner from "components/ui/Spinner/Spinner";
import { P } from "components/ui/Typography";

interface OptionSpanProps extends React.InputHTMLAttributes<HTMLSpanElement> {
  selected: boolean;
  active: boolean;
}

export const Wrapper = styled.div`
  position: relative;
`;

export const Input = styled((props) => (
  <Combobox.Input as={React.Fragment} {...props}>
    <input autoComplete="off" />
  </Combobox.Input>
))`
  border-radius: 4px;
  padding: 8px 36px 8px 16px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.bodyText};
  border: 2px solid ${({ theme }) => theme.colors.fieldBorder};
  width: 100%;
  max-width: 370px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5LjM2MDcgMjEuMDkyOUwxMi40MzA0IDE0LjE3OTJDMTEuODU4MSAxNC42NTA5IDExLjE4NjIgMTUuMDE5NyAxMC40MTQ3IDE1LjI4NTZDOS42NDMyMSAxNS41NTE1IDguODEzNjggMTUuNjg0NCA3LjkyNjEgMTUuNjg0NEM1Ljc0NiAxNS42ODQ0IDMuOTAwMSAxNC45Mjc4IDIuMzg4NCAxMy40MTQ1QzAuODc2NjY4IDExLjkwMTIgMC4xMjA4MDQgMTAuMDc1NSAwLjEyMDgwNCA3LjkzNzQ4QzAuMTIwODA0IDUuNzk5NDMgMC44Nzc0NTUgMy45NzM3NiAyLjM5MDc2IDIuNDYwNDhDMy45MDQwNCAwLjk0NzE5NCA1LjczNDU3IDAuMTkwNTUyIDcuODgyMzUgMC4xOTA1NTJDMTAuMDMwMSAwLjE5MDU1MiAxMS44NTU4IDAuOTQ3MTk0IDEzLjM1OTMgMi40NjA0OEMxNC44NjI5IDMuOTczNzYgMTUuNjE0NyA1LjgwMDg5IDE1LjYxNDcgNy45NDE4NkMxNS42MTQ3IDguNzk3MDIgMTUuNDg2OCA5LjYwNTI2IDE1LjIzMTEgMTAuMzY2NkMxNC45NzUzIDExLjEyNzkgMTQuNTkxNyAxMS44NDMzIDE0LjA4MDMgMTIuNTEyOUwyMS4wNjg5IDE5LjQ0MzFDMjEuMjk4OCAxOS42NzMyIDIxLjQxMzggMTkuOTQ2MSAyMS40MTM4IDIwLjI2MThDMjEuNDEzOCAyMC41Nzc1IDIxLjI4OTkgMjAuODUzNyAyMS4wNDIyIDIxLjA5MDRDMjAuODA3NCAyMS4zMzMgMjAuNTIzOCAyMS40NTQzIDIwLjE5MTMgMjEuNDU0M0MxOS44NTg5IDIxLjQ1NDMgMTkuNTgyIDIxLjMzMzkgMTkuMzYwNyAyMS4wOTI5Wk03LjkwMjczIDEzLjM3MzlDOS40MTI3NyAxMy4zNzM5IDEwLjY5MDUgMTIuODQzNyAxMS43MzYgMTEuNzgzM0MxMi43ODE0IDEwLjcyMyAxMy4zMDQxIDkuNDQxMDQgMTMuMzA0MSA3LjkzNzQ4QzEzLjMwNDEgNi40MzM5MiAxMi43ODA2IDUuMTUxOTcgMTEuNzMzNSA0LjA5MTYyQzEwLjY4NjUgMy4wMzEyNiA5LjQwOTU0IDIuNTAxMDggNy45MDI3MyAyLjUwMTA4QzYuMzc5NzMgMi41MDEwOCA1LjA4NzA4IDMuMDMxMjYgNC4wMjQ3OSA0LjA5MTYyQzIuOTYyNSA1LjE1MTk3IDIuNDMxMzYgNi40MzM5MiAyLjQzMTM2IDcuOTM3NDhDMi40MzEzNiA5LjQ0MTA0IDIuOTYxNyAxMC43MjMgNC4wMjIzNyAxMS43ODMzQzUuMDgzMDUgMTIuODQzNyA2LjM3NjUgMTMuMzczOSA3LjkwMjczIDEzLjM3MzlaIiBmaWxsPSIjQjNCNUJCIi8+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
`;

export const AutoCompleteOptions = styled(Combobox.Options)`
  position: absolute;
  padding: 0;
  z-index: 10000;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  padding: 4px;
  max-height: 300px;
  overflow-y: auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 4px 12px 0px rgba(0, 15, 23, 0.08),
    0px 2px 8px 0px rgba(0, 15, 23, 0.08);
`;

export const AutoCompleteOption = styled(Combobox.Option)`
  list-style: none;
`;

export const OptionSpan = styled(
  ({
    children,
    selected,
    active,
    disabled,
    ...otherProps
  }: OptionSpanProps) => <span {...otherProps}>{children}</span>
)`
  display: block;
  font-size: 16px;
  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.bodyText};
  background-color: ${({ theme, selected, active }) =>
    active ? theme.colors.secondaryBlue : "inherit"};
  border-radius: 4px;
  line-height: 28px;
  margin: 2px 0;
  padding: 0 16px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? "0.4" : "1")};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryBlue};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
  }
`;

export const DropdownListWrapper = styled.div`
  width: calc(100vw - 100px);
  max-width: 360px;
`;

export const Spinner = styled(uiSpinner)`
  display: block;
  width: 50px;
  color: #fb0;
  margin: 10px auto;
`;

export const NoMatchesLabel = styled(P)`
  margin: 0;
`;
