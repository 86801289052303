import styled from "styled-components";
import uiButton from "components/ui/Button";
import { Dialog as uiDialog } from "@headlessui/react";
import uiSpinner from "components/ui/Spinner/Spinner";
import CloseIcon from "components/icons/CloseIcon";
import { breakpointUp } from "helpers/breakpoints";

export const Button = styled(uiButton)`
  width: 100%;
  margin-bottom: 10px;

  svg {
    position: relative;
    top: -1px;
  }

  ${breakpointUp.md`
    width: auto;
    margin-bottom: 0;
  `}
`;

export const Dialog = styled(uiDialog)`
  position: relative;
  z-index: 1000;
`;

export const PanelWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
`;

export const Panel = styled(uiDialog.Panel)`
  position: relative;
  width: 100%;
  max-width: 500px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  text-align: center;
  padding: 30px 0;
`;

export const Title = styled(uiDialog.Title)`
  font-family: Ubuntu, sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
`;

export const Description = styled(uiDialog.Description)`
  text-align: center;
  margin: 0;
`;

export const Backdrop = styled(uiDialog.Backdrop)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const Spinner = styled(uiSpinner)`
  display: block;
  width: 40px;
  height: 40px;
  color: #fb0;
  margin: 10px auto 0 auto;
`;

export const ModalButton = styled(uiButton)`
  margin-top: 10px;
`;

export const DownloadLink = styled.a`
  display: inline-block;
  margin-top: 30px;
`;

export const CloseModalButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: transparent;

  :focus {
    outline: none;
  }
`;

export const CloseModalIcon = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.paleText};
`;
