import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { logAnalyticsEvent } from "services/analytics";

const DEFAULT_MAXIMUM_FILE_SIZE = 5000000;
const ACCEPTED_IMAGE_TYPES = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/svg": [".svg"],
};
const DEFAULT_IMAGE_SIZE = 500;

interface useImageUploadArgs {
  onChange?: Function;
  onScaleChange?: Function;
  maximumFileSize?: number;
  resizedImageSize?: number;
  imageDataURL?: string;
  imageScale?: number;
}

export const useImageUpload = ({
  onChange,
  onScaleChange,
  maximumFileSize,
  resizedImageSize,
  imageDataURL,
  imageScale,
}: useImageUploadArgs) => {
  const [sliderValue, setSliderValue] = useState(imageScale || 100);

  const handleSliderChange = (value: number) => {
    setSliderValue(value);
  };

  const handleSliderAfterChange = (value: number) => {
    if (onScaleChange) {
      onScaleChange(value);
    }
  };

  const resetImageUpload = () => {
    setDataURL(undefined);
    setSliderValue(100);
    if (onChange) {
      onChange(undefined);
    }
  };

  const [dataURL, setDataURL]: [
    string | undefined,
    Dispatch<SetStateAction<string | undefined>>
  ] = useState();
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: any) => {
      fileRejections.forEach((file: any) => {
        file.errors.forEach((err: any) => {
          if (err.code === "file-too-large") {
            alert("Error: The image file too big");
          }
          if (err.code === "file-invalid-type") {
            alert("Error: This file type is not allowed");
          }
        });
      });
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = () => {
          const dataURL: string = reader.result as string;
          const img = new global.Image();
          img.onload = function () {
            const maxImageDimension = resizedImageSize || DEFAULT_IMAGE_SIZE;
            const resizeRatio =
              maxImageDimension / Math.max(img.width, img.height);
            const resizedWidth = Math.round(img.width * resizeRatio);
            const resizedHeight = Math.round(img.height * resizeRatio);
            const canvas: HTMLCanvasElement = document.createElement("canvas");
            canvas.width = resizedWidth;
            canvas.height = resizedHeight;
            const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, resizedWidth, resizedHeight);
            ctx.drawImage(img, 0, 0, resizedWidth, resizedHeight);
            const resizedImageDataURL = canvas.toDataURL("image/jpeg");
            if (onChange) {
              onChange(resizedImageDataURL);
            }
            if (onScaleChange) {
              onScaleChange(100);
            }
          };
          img.src = dataURL;

          setDataURL(dataURL);
        };
        reader.readAsDataURL(file);
      }
    },
    [onChange, onScaleChange, resizedImageSize]
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
    accept: ACCEPTED_IMAGE_TYPES,
    maxSize: maximumFileSize || DEFAULT_MAXIMUM_FILE_SIZE,
  });

  useEffect(() => {
    if (imageDataURL) {
      setDataURL(imageDataURL);
    } else {
      setDataURL(undefined);
    }
  }, [imageDataURL]);

  const handleBrowseFilesButtonClick = () => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 3",
      label: "Browse files button",
    });
  };

  return {
    sliderValue,
    handleSliderChange,
    handleSliderAfterChange,
    dataURL,
    getRootProps,
    getInputProps,
    resetImageUpload,
    handleBrowseFilesButtonClick,
  };
};
