import { Component } from "react";
import { CardWrapper, CardTitle } from "./styles";

interface CardProps extends Component {
  title?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

function Card({ title, disabled, children, ...otherProps }: CardProps) {
  return (
    <CardWrapper disabled={!!disabled} {...otherProps}>
      {title && <CardTitle>{title}</CardTitle>}

      {!disabled && <>{children}</>}
    </CardWrapper>
  );
}
export default Card;
