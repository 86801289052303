import FundList from "components/forms/FundList";
import Card from "../ui/Card";
import { useInvestmentFunds } from "./hooks";
import { ExitTaxMessage, GovernmentLevyMessage } from "./styles";

const InvestmentFundsCard = ({ props }: any) => {
  const {
    availableFunds,
    showLevyMessage,
    investmentFundsCardDisabled,
    isSynergyInvestmentBond,
    isSynergyCorporateBond,
  } = useInvestmentFunds();
  const loading = availableFunds === undefined;

  return (
    <Card
      disabled={investmentFundsCardDisabled}
      title="Investment funds"
      {...props}
    >
      <FundList loading={loading} funds={availableFunds || []} />

      {showLevyMessage && (
        <GovernmentLevyMessage>
          <strong>Life Assurance Premium Levy: </strong>A 1% government levy
          applies to all investment products sold in Ireland. Remember to factor
          this in when calculating the net allocation.
        </GovernmentLevyMessage>
      )}

      {isSynergyInvestmentBond && (
        <ExitTaxMessage>
          <strong>Exit Tax: </strong>Realised gains for investment bonds are
          subject to an exit tax of 41%. There is a deemed disposal every eight
          years, where exit tax is deducted on unrealised gains on the 8th
          anniversary of the policy. Exit tax is deducted at source.
        </ExitTaxMessage>
      )}

      {isSynergyCorporateBond && (
        <ExitTaxMessage>
          <strong>Exit Tax: </strong>Realised gains for company investments are
          subject to an exit tax of 25%. There is a deemed disposal every eight
          years, where exit tax is deducted on unrealised gains on the 8th
          anniversary of the policy. Gains on unit linked funds are not subject
          to close company surcharge tax, as the returns from a life policy are
          not deemed as income, so the surcharge on undistributed investment
          income does not apply. Exit tax is deducted at source.
        </ExitTaxMessage>
      )}
    </Card>
  );
};
export default InvestmentFundsCard;
