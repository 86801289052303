import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  amcRebate: string;
  fbrc: string;
  includeAdditionalExpenses: boolean;
}

interface AMCRebateUpdate {
  amc: string;
  amcRebate: string;
}

interface FBRCUpdate {
  amc: string;
  fbrc: string;
}

const initialState: InitialState = {
  amcRebate: "",
  fbrc: "",
  includeAdditionalExpenses: false,
};

const chargesSlice = createSlice({
  name: "charges",
  initialState,
  reducers: {
    updateAMCRebate: (state, action: PayloadAction<AMCRebateUpdate>) => {
      const { amcRebate } = action.payload;
      state.amcRebate = amcRebate;
    },
    updateFBRC: (state, action: PayloadAction<FBRCUpdate>) => {
      const { fbrc } = action.payload;
      state.fbrc = fbrc;
    },
    updateIncludeAdditionalExpenses: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.includeAdditionalExpenses = action.payload;
    },
    reset: (state) => {
      state.amcRebate = initialState.amcRebate;
      state.fbrc = initialState.fbrc;
      state.includeAdditionalExpenses = initialState.includeAdditionalExpenses;
    },
  },
});

export default chargesSlice.reducer;
export const chargesActions = chargesSlice.actions;
