import { SVGProps } from "react";

interface ChevronIconProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const ChevronIcon = ({ title, ...otherProps }: ChevronIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    viewBox="0 0 729 448"
    {...otherProps}
  >
    {title && <title>{title}</title>}
    <path
      d="M1060.35 917.699c-32.53-32.522-32.53-85.329 0-117.851 32.52-32.522 85.32-32.522 117.85 0 0 0 139.47 139.477 221.78 221.782l222.15-221.825c32.54-32.498 85.35-32.459 117.85.086 32.5 32.546 32.46 85.353-.09 117.851l-281.07 280.668c-32.55 32.5-85.28 32.48-117.81-.04l-280.66-280.671Z"
      style={{
        fill: "currentColor",
      }}
      transform="translate(-1035.94 -775.44)"
    />
  </svg>
);
export default ChevronIcon;
