import { Amplify } from "aws-amplify";
import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { createAuthLink, AUTH_TYPE } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import awsconfig from "aws-exports";

Amplify.configure(awsconfig);

const authConfig = {
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: awsconfig.aws_appsync_authenticationType as AUTH_TYPE.API_KEY,
    apiKey: awsconfig.aws_appsync_apiKey,
  },
};

const authLink = createAuthLink(authConfig);
const subscriptionLink = createSubscriptionHandshakeLink(authConfig);

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, subscriptionLink]),
  cache: new InMemoryCache(),
});
