import { useSelector } from "app/hooks";
import { useDispatch } from "react-redux";
import { chargesActions } from "app/features/charges/chargesSlice";
import { investmentAmountActions } from "app/features/investmentAmount/investmentAmountSlice";
import { investmentDetailsActions } from "app/features/investmentDetails/investmentDetailsSlice";
import { investmentFundsActions } from "app/features/investmentFunds/investmentFundsSlice";
import { personaliseReportActions } from "app/features/personaliseReport/personaliseReportSlice";
import { useEffect, useState } from "react";
import { logAnalyticsEvent } from "services/analytics";

export const useCustomiseReportScreen = () => {
  const dispatch = useDispatch();
  const { product } = useSelector((state) => state.investmentDetails);
  const redirectHome = !product;
  const [rememberCustomisationOptions, setRememberCustomisationOptions] =
    useState(localStorage.getItem("rememberCustomisationOptions") === "true");

  const handleStartNewReport = () => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 3",
      label: "Start new report button",
    });
    dispatch(chargesActions.reset());
    dispatch(investmentAmountActions.reset());
    dispatch(investmentDetailsActions.reset());
    dispatch(investmentFundsActions.reset());
    dispatch(personaliseReportActions.reset());
    global.location.reload();
  };

  const updateRememberCustomisationOptions = (value: boolean) => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 3",
      label: `Remember details | ${value ? "True" : "False"}`,
    });
    if (value) {
      localStorage.setItem("rememberCustomisationOptions", value.toString());
    } else {
      localStorage.removeItem("rememberCustomisationOptions");
    }
    setRememberCustomisationOptions(value);
  };

  useEffect(() => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 3",
      label: "Display",
    });
  }, []);

  const handleBack = () => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 3",
      label: "Back button",
    });
  };

  return {
    redirectHome,
    handleStartNewReport,
    rememberCustomisationOptions,
    updateRememberCustomisationOptions,
    handleBack,
  };
};
