import { ReactElement, SVGProps } from "react";

const Logo = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={199}
    height={44}
    fill="none"
    viewBox="0 0 199 44"
    {...props}
  >
    <path fill="#FB0" d="m174.226 16.092 4.777 9.393L190 4l-15.774 12.092Z" />
    <path
      fill="currentColor"
      d="M6.864 39.225c.54.011 1.08-.043 1.608-.16a3.065 3.065 0 0 0 1.024-.448 1.48 1.48 0 0 0 .531-.657c.102-.274.152-.565.148-.858a1.989 1.989 0 0 0-.948-1.67 13.618 13.618 0 0 0-3.253-1.435c-.67-.237-1.34-.505-2.01-.813a7.317 7.317 0 0 1-1.788-1.153 5.47 5.47 0 0 1-1.3-1.685 5.29 5.29 0 0 1-.505-2.413c-.019-.883.16-1.76.523-2.565a5.41 5.41 0 0 1 1.506-1.94 6.914 6.914 0 0 1 2.368-1.224 10.598 10.598 0 0 1 3.128-.446c1.207-.02 2.41.13 3.575.447.862.249 1.696.587 2.489 1.01l-1.341 3.637a12.765 12.765 0 0 0-1.895-.805 8.212 8.212 0 0 0-2.529-.34c-1.102 0-1.894.15-2.377.448a1.537 1.537 0 0 0-.728 1.403c-.012.336.082.667.268.947.203.279.46.514.755.693.354.224.73.413 1.121.563.425.17.894.34 1.408.518a30.87 30.87 0 0 1 2.78 1.166 7.437 7.437 0 0 1 1.966 1.34 4.887 4.887 0 0 1 1.17 1.788c.273.81.402 1.662.38 2.516 0 1.913-.669 3.402-2.006 4.468-1.338 1.067-3.36 1.6-6.068 1.6a16.978 16.978 0 0 1-2.458-.16 16.667 16.667 0 0 1-1.935-.398 11.5 11.5 0 0 1-1.452-.505c-.348-.15-.688-.32-1.019-.505l1.3-3.664c.723.375 1.482.675 2.266.893 1.074.29 2.185.428 3.298.407ZM17.06 23.313l4.389-.71v4.584h5.313v3.669h-5.313v5.47c-.04.774.128 1.545.487 2.233.324.549.98.824 1.966.827a8.359 8.359 0 0 0 1.465-.134c.477-.086.944-.22 1.395-.398l.62 3.432a12.18 12.18 0 0 1-1.787.563c-.774.173-1.566.257-2.359.25a7.86 7.86 0 0 1-2.99-.487 4.656 4.656 0 0 1-1.894-1.34 5.06 5.06 0 0 1-.988-2.114 12.441 12.441 0 0 1-.281-2.752l-.022-13.093Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M38.268 27.236a10.144 10.144 0 0 0-3.258-.447c-.513 0-1.05.014-1.55.063s-.983.102-1.434.174c-.452.072-.872.148-1.256.237-.384.09-.706.174-.96.255l.562 3.547a9.762 9.762 0 0 1 1.89-.446c.74-.108 1.487-.162 2.235-.161 1.121 0 1.917.232 2.364.679.462.478.709 1.123.683 1.787v.416a6.667 6.667 0 0 0-1.081-.192 13.898 13.898 0 0 0-1.524-.076 11.894 11.894 0 0 0-2.453.25c-.741.15-1.451.426-2.1.813a4.24 4.24 0 0 0-1.453 1.52 4.518 4.518 0 0 0-.545 2.305 5.424 5.424 0 0 0 .492 2.364c.299.638.761 1.185 1.34 1.587.64.431 1.358.734 2.114.893.9.186 1.82.274 2.74.264 1.364.016 2.728-.077 4.079-.277a55.847 55.847 0 0 0 2.632-.492V33.13a9.826 9.826 0 0 0-.326-2.632 4.669 4.669 0 0 0-1.109-2.01 5.063 5.063 0 0 0-2.082-1.252ZM36.61 39.658c-.398.027-.795.027-1.242.027v-.018a3.861 3.861 0 0 1-1.908-.41 1.515 1.515 0 0 1-.697-1.435c-.012-.315.07-.626.237-.894.163-.242.389-.436.652-.563.303-.143.628-.237.96-.277a8.649 8.649 0 0 1 2.146 0c.353.04.62.08.8.12v3.361a9.17 9.17 0 0 1-.948.09Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M44.85 27.718a25.15 25.15 0 0 1 2.9-.607 23.713 23.713 0 0 1 3.727-.282 9.12 9.12 0 0 1 3.298.519c.81.3 1.527.804 2.082 1.465.526.655.9 1.418 1.095 2.235.227.95.337 1.923.326 2.9v8.798h-4.406v-8.253c0-1.421-.188-2.427-.563-3.017-.375-.59-1.075-.887-2.1-.893-.318 0-.653 0-1.006.045-.353.044-.67.062-.947.102v12.016H44.85V27.718Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M73.703 42.58c.492-.106.947-.218 1.34-.339v-22.45l-4.405.711v7.033a8.889 8.889 0 0 0-1.466-.5 6.78 6.78 0 0 0-1.64-.179 7.289 7.289 0 0 0-2.945.563 5.809 5.809 0 0 0-2.171 1.627 7.508 7.508 0 0 0-1.363 2.587 11.58 11.58 0 0 0-.474 3.418 9.666 9.666 0 0 0 .55 3.356c.333.952.868 1.821 1.568 2.547a6.904 6.904 0 0 0 2.543 1.613 9.768 9.768 0 0 0 3.463.563c.532 0 1.09-.026 1.671-.076a30.04 30.04 0 0 0 1.716-.192c.563-.08 1.121-.174 1.613-.281Zm-7.708-4.463a5.39 5.39 0 0 1-.916-3.285c-.003-2.83 1.043-4.246 3.137-4.25.453 0 .903.06 1.34.18a3.72 3.72 0 0 1 1.082.447v8.003c-.128.023-.275.039-.44.057-.12.013-.248.027-.387.045-.335.045-.702.045-1.095.045a3.168 3.168 0 0 1-2.721-1.242ZM87.104 27.236a10.11 10.11 0 0 0-3.253-.447c-.514 0-1.055.014-1.56.063-.504.049-.978.102-1.434.174-.456.072-.876.148-1.26.237-.384.09-.702.174-.96.255l.562 3.548a9.72 9.72 0 0 1 1.895-.447c.74-.109 1.487-.163 2.234-.161 1.126 0 1.922.232 2.368.68.46.478.705 1.123.68 1.786v.416a6.577 6.577 0 0 0-1.082-.192 13.923 13.923 0 0 0-1.523-.076 11.965 11.965 0 0 0-2.454.25 6.75 6.75 0 0 0-2.1.813 4.24 4.24 0 0 0-1.466 1.51 4.562 4.562 0 0 0-.527 2.315 5.426 5.426 0 0 0 .492 2.364c.299.638.761 1.185 1.34 1.587a6.007 6.007 0 0 0 2.118.893c.9.186 1.817.274 2.735.264 1.365.016 2.73-.077 4.08-.277 1.123-.188 2-.352 2.632-.492v-9.151c.01-.888-.099-1.773-.322-2.632a4.724 4.724 0 0 0-1.113-2.01 5.062 5.062 0 0 0-2.082-1.27Zm-1.658 12.422c-.393.027-.795.027-1.242.027v-.018a3.875 3.875 0 0 1-1.908-.393 1.51 1.51 0 0 1-.693-1.434 1.541 1.541 0 0 1 .237-.894 1.63 1.63 0 0 1 .648-.563c.303-.143.628-.237.96-.277a8.781 8.781 0 0 1 2.146-.018c.268.027.535.067.8.12v3.361c-.315.046-.63.076-.948.09Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M103.066 31.093a30.97 30.97 0 0 0-1.39-.313 8.674 8.674 0 0 0-1.716-.161 7.913 7.913 0 0 0-.992.072c-.294.03-.586.086-.871.165v11.89H93.69V28c.917-.304 1.849-.559 2.793-.764a16.188 16.188 0 0 1 3.597-.37c.237 0 .518 0 .853.044.335.045.675.072 1.01.116.335.045.67.112 1.006.179.293.057.581.142.858.255l-.742 3.633Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M117.454 42.58c.492-.106.948-.218 1.341-.339l.004-22.45-4.406.711v7.038a8.99 8.99 0 0 0-1.488-.5 6.778 6.778 0 0 0-1.64-.18 7.29 7.29 0 0 0-2.909.564 5.805 5.805 0 0 0-2.171 1.626 7.427 7.427 0 0 0-1.363 2.587 11.563 11.563 0 0 0-.474 3.419 9.681 9.681 0 0 0 .55 3.356 6.748 6.748 0 0 0 4.124 4.155 9.772 9.772 0 0 0 3.432.563c.532 0 1.09-.026 1.671-.076.581-.049 1.153-.111 1.716-.192.563-.08 1.122-.174 1.613-.281Zm-7.73-4.463a5.387 5.387 0 0 1-.894-3.285c-.003-2.83 1.035-4.246 3.115-4.25.452 0 .903.06 1.34.18.382.09.747.241 1.082.447v8.003c-.128.023-.275.039-.441.057-.118.013-.247.027-.386.045-.335.045-.702.045-1.095.045a3.162 3.162 0 0 1-2.721-1.242Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M140.239 38.783v3.963h-13.696V22.25h4.616v16.534h9.08Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M146.432 24.6a2.423 2.423 0 0 0 .787-1.908 2.423 2.423 0 0 0-.787-1.909 2.797 2.797 0 0 0-3.695 0 2.417 2.417 0 0 0-.782 1.909c-.037.72.249 1.42.782 1.908a2.81 2.81 0 0 0 3.695 0Zm-4.035 18.146h4.406v-15.56h-4.406v15.56Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M156.321 19.792a9.778 9.778 0 0 1 2.279.25c.544.123 1.077.287 1.595.492l-.858 3.516a5.92 5.92 0 0 0-1.287-.389 6.765 6.765 0 0 0-1.287-.134 3.599 3.599 0 0 0-1.34.224 2.122 2.122 0 0 0-.858.603 2.389 2.389 0 0 0-.447.92 4.712 4.712 0 0 0-.134 1.153v.77h5.443v3.659h-5.443v11.89h-4.406V26.343c0-2.014.566-3.612 1.698-4.795 1.132-1.183 2.814-1.768 5.045-1.756Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M160.964 31.459a9.507 9.507 0 0 0-.639 3.624 10.244 10.244 0 0 0 .478 3.195c.307.954.823 1.829 1.51 2.56a7.05 7.05 0 0 0 2.574 1.698 9.77 9.77 0 0 0 3.66.635c.554.001 1.107-.029 1.658-.09.554-.058 1.067-.13 1.555-.219.443-.079.882-.184 1.313-.313.325-.091.642-.21.948-.353l-.59-3.574a8.942 8.942 0 0 1-1.908.545 12.81 12.81 0 0 1-2.382.223 5.102 5.102 0 0 1-3.016-.8 2.924 2.924 0 0 1-1.274-2.158h9.996c.01-.105.017-.217.023-.335.009-.15.018-.309.035-.478.032-.304.032-.586.032-.845.003-2.618-.638-4.604-1.922-5.956-1.284-1.353-3.029-2.029-5.237-2.029a7.499 7.499 0 0 0-2.748.514c-.891.355-1.7.889-2.377 1.568a7.715 7.715 0 0 0-1.689 2.588Zm9.54.844c.109.356.173.724.192 1.095h-5.831c.058-.378.152-.749.281-1.108.118-.353.303-.68.545-.96.247-.284.552-.512.894-.667.399-.179.832-.266 1.269-.254a2.77 2.77 0 0 1 1.287.268c.331.163.623.394.858.679.229.28.401.601.505.947Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Logo;
