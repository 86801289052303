import styled from "styled-components";
import uiLogo from "../Brand/Logo";
import { breakpointUp } from "helpers/breakpoints";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: ${({ theme }) => theme.colors.headerBackground};
  padding: 0 20px;
  margin-bottom: 30px;

  ${breakpointUp.md`
    padding: 0 40px;
    margin-bottom: 70px;
  `}

  ${breakpointUp.lg`
    padding: 0 100px;
  `}
`;

export const Logo = styled(uiLogo)`
  width: 100px;
  height: auto;
  margin-top: -10px;
  color: ${({ theme }) => theme.colors.white};

  ${breakpointUp.md`
    width: 200px;
  `}
`;

export const Title = styled.h1`
  flex: 1;
  text-align: right;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;

  ${breakpointUp.md`
    font-size: 24px;
  `}
`;
