import { logAnalyticsEvent } from "services/analytics";

const useReportSectionsCard = () => {
  const handleExampleReportClick = () => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 3",
      label: "Download example report link",
    });
  };

  return {
    handleExampleReportClick,
  };
};

export default useReportSectionsCard;
