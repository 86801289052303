import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InvestmentDetailsCard from "components/InvestmentDetailsCard";
import InvestmentFundsCard from "components/InvestmentFundsCard";
import InvestmentAmountCard from "components/InvestmentAmountCard";
import ChargesCard from "components/ChargesCard";
import { Button, Link, NavigationFooter } from "./styles";
import { useReportDetails } from "./hooks";
import Header from "components/Header";
import ProgressStep from "components/ProgressStep/ProgressStep";
import Footer from "components/Footer";
import CookieBanner from "components/CookieBanner";
import IntroductionModal from "./IntroductionModal/IntroductionModal";

function ReportDetails() {
  const { FormProvider, methods, continuteButtonDisabled, handleContinue } =
    useReportDetails();
  const url = continuteButtonDisabled ? "" : "reportingperiod";

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col>
            <ProgressStep
              title="Report details"
              currentStep={1}
              totalSteps={3}
            />

            <FormProvider {...methods}>
              <form autoComplete="off">
                <InvestmentDetailsCard />

                <InvestmentFundsCard />

                <InvestmentAmountCard />

                <ChargesCard />
              </form>
            </FormProvider>

            <NavigationFooter>
              <Link to={url}>
                <Button
                  disabled={continuteButtonDisabled}
                  onClick={handleContinue}
                >
                  Continue
                </Button>
              </Link>
            </NavigationFooter>
          </Col>
        </Row>
      </Container>
      <Footer />

      <CookieBanner />

      <IntroductionModal />
    </>
  );
}

export default ReportDetails;
