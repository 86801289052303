const theme = {
  colors: {
    primaryText: "#051a3f",
    primaryAction: "#147cb3",
    primaryBlue: "#0a2f73",
    paleBlue: "#eff6fa",
    paleGrey: "#f8f9fb",
    bodyText: "#051a3f",
    paleText: "#656a76",
    fieldBorder: "#a8aab1",
    progressComplete: "#008616",
    progressCurrent: "#4396c2",
    previousStep: "#007214",
    currentStep: "#4396c2",
    nextStep: "#d9dbdd",
    cardBorder: "#d9dbdd",
    headerBackground: "#0a2f73",
    cardTitle: "#0a2f73",
    featureCard: "#f1f3f7",
    loadingSkeleton: "rgba(217, 219, 221, 0.5)",
    secondaryBlue: "#147cb3",
    black: "#000000",
    white: "#ffffff",
    error: "#ca0000",
  },
};

export default theme;
