import styled from "styled-components";
import { Switch } from "@headlessui/react";
import uiHelpTooltip from "components/ui/HelpTooltip/HelpTooltip";
import { breakpointUp } from "helpers/breakpoints";

export const Wrapper = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.cardTitle};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-top: 0;

  &:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

export const LabelCol = styled.div`
  flex: 1;
  border-top-left-radius: 5px;
  padding: 13px 0 16px 32px;
  font-size: 16px;
  line-height: 1.2;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const ToggleCol = styled.div`
  display: flex;
  flex: 0 1 95px;
  border-top-right-radius: 5px;
  padding-right: 32px;
  align-items: center;
  justify-content: center;

  ${breakpointUp.sm`
    flex: 0 1 160px;
  `}
`;

export const ToggleSwitch = styled(({ enabled, children, ...otherProps }) => (
  <Switch {...otherProps}>{children}</Switch>
))`
  background-color: ${({ theme, enabled }) =>
    enabled ? "green" : theme.colors.cardBorder};
  width: 48px;
  height: 30px;
  border-radius: 15px;
  text-align: left;
  transition: background-color linear 200ms;
`;

export const SwitchButton = styled(({ enabled, children, ...otherProps }) => (
  <span {...otherProps}>{children}</span>
))`
  display: inline-block;
  width: 28px;
  height: 28px;
  margin: 1px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  transform: ${({ enabled }) =>
    enabled ? "translateX(18px)" : "translateX(0)"};
`;

export const HelpTooltip = styled(uiHelpTooltip)`
  position: relative;
  top: 5px;
`;
