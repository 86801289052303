import cookie from "cookiejs";
import { useEffect, useState } from "react";
import { SL_COOKIE_NAME, logAnalyticsEvent } from "services/analytics";

export const useIntroductionModal = () => {
  let [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  function closeModal() {
    setIsModalOpen(false);
    logAnalyticsEvent({
      category: "Start",
      action: "Welcome",
      label: "Start now button",
    });
    localStorage.setItem("hideIntroductionModal", "true");
  }

  function handleViewReport() {
    logAnalyticsEvent({
      category: "Start",
      action: "Welcome",
      label: "Download example report button",
    });
  }

  useEffect(() => {
    setTimeout(() => {
      if (
        cookie.get(SL_COOKIE_NAME) &&
        localStorage.getItem("hideIntroductionModal") !== "true"
      ) {
        setIsModalOpen(true);
        logAnalyticsEvent({
          category: "Start",
          action: "Welcome",
          label: "Display",
        });
      }
    }, 750);
  }, []);

  return {
    isModalOpen,
    closeModal,
    handleViewReport,
  };
};
