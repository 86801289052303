import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "app/hooks";
import { investmentDetailsActions } from "../../app/features/investmentDetails/investmentDetailsSlice";
import { logAnalyticsEvent } from "services/analytics";

export const useInvestmentDetails = () => {
  const dispatch = useDispatch();
  const {
    product,
    clientName,
    hasDrawdown,
    hasSingle,
    hasRegular,
    drawdownType,
    drawdownPercent: drawdown,
    drawdownMonetaryAmount: drawdownMonetary,
  } = useSelector((state) => state.investmentDetails);
  const {
    formState: { errors },
  } = useFormContext();

  const logTooltipAnalyticsEventWithdrawal5PercentCap = () => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 1",
      label: "Withdrawal 5% cap tooltip",
    });
  };

  const handleProductChange = (product: string) => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 1",
      label: product,
    });
    dispatch(investmentDetailsActions.updateProduct(product));
  };

  const handleClientNameChange = (clientName: string) => {
    dispatch(investmentDetailsActions.updateClientName(clientName));
  };

  const handleHasDrawdownChange = (value: boolean) => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 1",
      label: value ? "Yes" : "No",
    });
    dispatch(investmentDetailsActions.updateHasDrawdown(value));
    if (!value) {
      dispatch(investmentDetailsActions.updateDrawdownMonetaryAmount(0));
      dispatch(investmentDetailsActions.updateDrawdownPercent(0));
    }
  };

  const handleHasSinglePremium = (value: boolean) => {
    dispatch(investmentDetailsActions.updateHasSinglePremium(value));
  };

  const handleHasRegularPremium = (value: boolean) => {
    dispatch(investmentDetailsActions.updateHasRegularPremium(value));
  };

  const handleDrawdownTypeChange = (value: string) => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 1",
      label: value,
    });
    dispatch(investmentDetailsActions.updateDrawdownType(value));
    if (!value) {
      dispatch(investmentDetailsActions.updateDrawdownMonetaryAmount(0));
      dispatch(investmentDetailsActions.updateDrawdownPercent(0));
    }

    // ensure the one that is currently selected is updated
    if (value === "percent") {
      dispatch(investmentDetailsActions.updateDrawdownPercent(drawdown));
    }
    if (value === "monetary") {
      dispatch(
        investmentDetailsActions.updateDrawdownMonetaryAmount(drawdownMonetary)
      );
    }
  };

  const handleDrawdownChange = (amount: number) => {
    if (!isNaN(amount)) {
      dispatch(investmentDetailsActions.updateDrawdownPercent(amount));
    }
  };

  const handleDrawdownBlur = (amount: number) => {
    if (isNaN(amount)) {
      dispatch(investmentDetailsActions.updateDrawdownPercent(0));
    }
    if (!isNaN(amount)) {
      dispatch(investmentDetailsActions.updateDrawdownPercent(amount));
    }
  };

  const handleDrawdownMonetaryAmountChange = (amount: number) => {
    if (!isNaN(amount)) {
      dispatch(investmentDetailsActions.updateDrawdownMonetaryAmount(amount));
    }
  };

  const handleDrawdownMonetaryAmountBlur = (amount: number) => {
    if (isNaN(amount)) {
      dispatch(investmentDetailsActions.updateDrawdownMonetaryAmount(0));
    }
    if (!isNaN(amount)) {
      dispatch(investmentDetailsActions.updateDrawdownMonetaryAmount(amount));
    }
  };

  return {
    clientName,
    handleClientNameChange,
    product,
    handleProductChange,
    hasDrawdown,
    handleHasDrawdownChange,
    drawdown,
    drawdownMonetary,
    handleDrawdownTypeChange,
    drawdownType,
    handleDrawdownChange,
    handleDrawdownBlur,
    handleDrawdownMonetaryAmountChange,
    handleDrawdownMonetaryAmountBlur,
    handleHasRegularPremium,
    handleHasSinglePremium,
    logTooltipAnalyticsEventWithdrawal5PercentCap,
    hasSingle,
    hasRegular,
    errors,
  };
};
