import { useFormContext } from "react-hook-form";
import { chargesActions } from "app/features/charges/chargesSlice";
import { useDispatch, useSelector } from "app/hooks";
import { useEffect, useState } from "react";
import chargesData from "../../data/charges.json";
import { logAnalyticsEvent } from "services/analytics";

type ChargesFund = {
  name: string;
  citicode: string;
  allocation: number | undefined;
  additionalExpense: number | undefined;
};

const roundToDecimalPlaces = (value: number, decimals: number): number =>
  Math.round((value + Number.EPSILON) * Math.pow(10, decimals)) /
  Math.pow(10, decimals);

export const useCharges = () => {
  const dispatch = useDispatch();
  const { clientName, product, drawdownPercent } = useSelector(
    (state) => state.investmentDetails
  );
  const { investmentAmount, netAllocation } = useSelector(
    (state) => state.investmentAmount
  );
  const { averageAMC, totalAllocation } = useSelector(
    (state) => state.investmentFunds
  );
  const amc = averageAMC ? roundToDecimalPlaces(averageAMC, 2) : averageAMC;
  const { amcRebate, fbrc, includeAdditionalExpenses } = useSelector(
    (state) => state.charges
  );
  const { selectedFunds } = useSelector((state) => state.investmentFunds);
  const {
    formState: { errors },
  } = useFormContext();

  const [effectivePortfolioAMC, setEffectivePortfolioAMC] = useState(0);
  const [fundCharges, setFundCharges] = useState([] as ChargesFund[]);
  const [blendedAdditionalExpenses, setBlendedAdditionalExpenses] = useState(0);

  const chargesCardDisabled =
    !clientName ||
    !product ||
    drawdownPercent === null ||
    drawdownPercent > 100 ||
    totalAllocation !== 100 ||
    investmentAmount === 0 ||
    !netAllocation ||
    parseInt(netAllocation, 10) < 92 ||
    parseInt(netAllocation, 10) > 105;

  const updateAMCRebate = (value: string) => {
    dispatch(
      chargesActions.updateAMCRebate({
        amc: amc?.toString() || "",
        amcRebate: value,
      })
    );
  };

  const updateFBRC = (value: string) => {
    dispatch(
      chargesActions.updateFBRC({ amc: amc?.toString() || "", fbrc: value })
    );
  };

  const updateIncludeAdditionalExpenses = (value: boolean) => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 1",
      label: value ? "Yes" : "No",
    });
    dispatch(chargesActions.updateIncludeAdditionalExpenses(value));
  };

  useEffect(() => {
    const charges: ChargesFund[] = selectedFunds
      .map(({ name, citicode, allocation }) => {
        let { additionalExpense } = (chargesData as any)[citicode] || {
          additionalExpense: "0%",
        };
        additionalExpense = parseFloat(
          additionalExpense.toString().replace("%", "")
        );
        return {
          name,
          citicode,
          allocation,
          additionalExpense,
        };
      })
      .filter((fund) => !!fund.allocation);

    const additionalExpenses = charges.reduce(
      (accumulator, fund) =>
        parseFloat(
          (
            accumulator +
            ((fund.additionalExpense as number) * (fund.allocation as number)) /
              100
          ).toFixed(10)
        ),
      0
    );

    setFundCharges(charges);
    setBlendedAdditionalExpenses(roundToDecimalPlaces(additionalExpenses, 2));
  }, [selectedFunds]);

  useEffect(() => {
    const effectiveAMC = parseFloat(
      (
        (amc || 0) -
        parseFloat(amcRebate || "0") +
        parseFloat(fbrc || "0")
      ).toFixed(10)
    );
    setEffectivePortfolioAMC(effectiveAMC);
  }, [amc, amcRebate, fbrc]);

  return {
    amc,
    amcRebate,
    updateAMCRebate,
    fbrc,
    updateFBRC,
    effectivePortfolioAMC,
    chargesCardDisabled,
    fundCharges,
    includeAdditionalExpenses,
    updateIncludeAdditionalExpenses,
    blendedAdditionalExpenses,
    errors,
  };
};
