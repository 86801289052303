import { v4 as uuid } from "uuid";
import { SVG, TooltipContent, Wrapper } from "./styles";

interface HelpTooltipProps {
  onClick?: Function;
  children?: string;
}

const HelpTooltip = ({ children, onClick, ...props }: HelpTooltipProps) => {
  const id = uuid();
  return (
    <Wrapper tabIndex={-1} {...props}>
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        data-tooltip-id={id}
        onClick={(e) => {
          onClick && onClick(e);
        }}
      >
        <path
          fill="currentColor"
          d="M9 6a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM10 15a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v5a1 1 0 0 0 1 1Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10Zm-10 8a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
          clipRule="evenodd"
        />
      </SVG>
      <TooltipContent id={id} content={children} />
    </Wrapper>
  );
};
export default HelpTooltip;
