import cookie from "cookiejs";
import { useLayoutEffect, useState } from "react";
import { SL_COOKIE_NAME, SL_COOKIE_POLICY_NAME } from "services/analytics";

const setCookie = (
  cname: string,
  cvalue: string,
  exdays: number,
  domain: string
) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie =
    cname + "=" + cvalue + ";" + expires + ";path=/;domain=." + domain;
};

const getCookieDomain = () => {
  const domainParts = window.location.host.split(":")[0].split(".");
  return domainParts.slice(-2).join(".");
};

export const useCookieBanner = () => {
  const [visible, setVisible] = useState(false);

  useLayoutEffect(() => {
    const cookieValue = cookie.get(SL_COOKIE_NAME);
    if (!cookieValue || !JSON.parse(cookieValue as string).cookiecategoryids) {
      setVisible(true);
    }
  }, []);

  const handleDeclineAll = () => {
    setCookie(
      SL_COOKIE_NAME,
      '{"cookiecategoryids":"1"}',
      180,
      getCookieDomain()
    );
    setCookie(
      SL_COOKIE_POLICY_NAME,
      "isOptIn=true&reprompt=false",
      180,
      getCookieDomain()
    );
    setVisible(false);
    window.location.reload();
  };

  const handleAcceptAll = () => {
    setCookie(
      SL_COOKIE_NAME,
      '{"cookiecategoryids":"1|2|3"}',
      180,
      getCookieDomain()
    );
    setCookie(
      SL_COOKIE_POLICY_NAME,
      "isOptIn=true&reprompt=false",
      180,
      getCookieDomain()
    );
    setVisible(false);
    window.location.reload();
  };

  return {
    visible,
    handleDeclineAll,
    handleAcceptAll,
  };
};
