export const transformDMYToISODate = (date: string) => {
  const dateParts = date.split("/");
  if (dateParts.length === 3) {
    return dateParts.reverse().join("-");
  }
  return date;
};

export const transformISOToDMYDate = (date: string) => {
  const dateParts = date.split("-");
  if (dateParts.length === 3) {
    return dateParts.reverse().join("/");
  }
  return date;
};
