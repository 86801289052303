import Card from "../ui/Card";
import PerformanceChart from "./PerformanceChart/PerformanceChart";

const PerformancePreviewCard = ({ props }: any) => {
  return (
    <Card title="Portfolio performance preview" {...props}>
      <PerformanceChart />
    </Card>
  );
};
export default PerformancePreviewCard;
