import { breakpointUp } from "helpers/breakpoints";
import styled from "styled-components";
import ImageUploader from "./ImageUpload";
import { P } from "components/ui/Typography";

export const Input = styled.input`
  border: 2px solid ${({ theme }) => theme.colors.fieldBorder};
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  max-width: 370px;
  color: ${({ theme }) => theme.colors.bodyText};
`;

export const TextArea = styled.textarea`
  border: 2px solid ${({ theme }) => theme.colors.fieldBorder};
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  height: 120px;
  color: ${({ theme }) => theme.colors.bodyText};
`;

export const CharacterCount = styled(P)`
  margin-top: -7px;
  font-size: 14px;
  margin-bottom: 15px;
`;

export const Label = styled.label`
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0 8px 0;
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const LogoUploader = styled(ImageUploader)`
  margin-bottom: 40px;

  .file-upload-wrapper {
    max-width: 370px;
  }

  .image-preview {
    min-height: 250px;
  }

  .image-preview,
  .image-scaling {
    max-width: 370px;
  }

  .scaling-help-text {
    color: ${({ theme }) => theme.colors.paleText};
    font-size: 14px;
  }

  ${breakpointUp.sm`
    margin-bottom: 20px;
  `}

  ${breakpointUp.lg`
    .image-preview-wrapper{
      display: flex;
    }
    .image-preview {
        margin-bottom: 0;
    }
    .image-scaling {
      margin-left: 30px;
      margin-top: 50px;
      max-width: 300px;
    }
  `}
`;
