import uiSpinner from "components/ui/Spinner/Spinner";
import styled from "styled-components";

export const Spinner = styled(uiSpinner)`
  display: block;
  width: 50px;
  color: #fb0;
  margin: 10px auto 15px auto;
`;

export const SpinnerLabel = styled.p`
  font-size: 14px;
  text-align: center;
`;
