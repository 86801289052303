import { useDispatch, useSelector } from "app/hooks";
import { investmentFundsActions } from "app/features/investmentFunds/investmentFundsSlice";
import { useEffect } from "react";
import { logAnalyticsEvent } from "services/analytics";

export const useReportingPeriod = () => {
  const dispatch = useDispatch();
  const { selectedFunds, reportingPeriod } = useSelector(
    (state) => state.investmentFunds
  );
  const maximumReportingPeriod = selectedFunds.length
    ? selectedFunds.reduce((accumulator, fund) =>
        accumulator.availableReportingPeriod < fund.availableReportingPeriod
          ? accumulator
          : fund
      ).availableReportingPeriod
    : 0;

  useEffect(() => {
    if (!reportingPeriod) {
      dispatch(
        investmentFundsActions.updateReportingPeriod(maximumReportingPeriod)
      );
    }
  }, [reportingPeriod, maximumReportingPeriod, dispatch]);

  const handleReportingPeriodChange = (years: number) => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 2",
      label: `Reporting period - ${years}`,
    });
    dispatch(investmentFundsActions.updateReportingPeriod(years));
  };

  const handleTooltip = () => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 2",
      label: "Select the reporting period below tooltip",
    });
  };

  return {
    selectedFunds,
    maximumReportingPeriod,
    reportingPeriod,
    handleReportingPeriodChange,
    handleTooltip,
  };
};
