import { nanoid } from "@reduxjs/toolkit";
import { personaliseReportActions } from "app/features/personaliseReport/personaliseReportSlice";
import { useSelector } from "app/hooks";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { logAnalyticsEvent } from "services/analytics";
import { uploadImage } from "services/image";

export const useReportDetails = () => {
  const dispatch = useDispatch();
  const methods = useForm({ mode: "onBlur" });
  const { clientName, product, drawdownPercent, drawdownMonetaryAmount } =
    useSelector((state) => state.investmentDetails);
  const { investmentAmount, netAllocation } = useSelector(
    (state) => state.investmentAmount
  );
  const { totalAllocation, averageAMC } = useSelector(
    (state) => state.investmentFunds
  );
  const { logoImage } = useSelector((state) => state.personaliseReport);
  const { amcRebate, fbrc } = useSelector((state) => state.charges);

  useEffect(() => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 1",
      label: "Display",
    });
  }, []);

  useEffect(() => {
    if (logoImage) {
      const logoImageId = nanoid();
      dispatch(personaliseReportActions.updateLogoImageId(logoImageId));
      uploadImage(logoImage, logoImageId);
    }
  }, [logoImage, dispatch]);

  const handleContinue = () => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 1",
      label: "Continue button",
    });
  };

  const continuteButtonDisabled =
    !clientName ||
    !product ||
    drawdownPercent === null ||
    drawdownPercent > 100 ||
    totalAllocation !== 100 ||
    (investmentAmount === 0 && drawdownMonetaryAmount > -1) ||
    !netAllocation ||
    parseFloat(netAllocation) < 92 ||
    parseFloat(netAllocation) > 105 ||
    amcRebate === "" ||
    parseFloat(amcRebate) > 1.55 ||
    fbrc === "" ||
    parseFloat(fbrc) > 1 ||
    (averageAMC as number) - parseFloat(amcRebate) + parseFloat(fbrc) < 0;

  return {
    FormProvider,
    methods,
    continuteButtonDisabled,
    handleContinue,
  };
};
