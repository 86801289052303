import * as React from "react";
import { SVGProps } from "react";

interface CreateReportIconProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

const CreateReportIcon = ({ title, ...otherProps }: CreateReportIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    viewBox="0 0 48 48"
    {...otherProps}
  >
    {title && <title>{title}</title>}
    <path
      d="M50 2a2 2 0 0 0-2-2H26a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2V2Z"
      style={{
        fill: "#f1f3f7",
      }}
      transform="translate(-2.05)"
    />
    <path
      d="M28 6h6v2h-6zM28 11h18v2H28zM28 16h18v2H28zM28 21h18v2H28zM28 26h13v2H28z"
      style={{
        fill: "#d9dbdd",
        fillRule: "nonzero",
      }}
      transform="translate(-2.05)"
    />
    <path
      d="M12 26h12l9.58 17.563c1.09 2-.356 4.437-2.634 4.437H5.054c-2.278 0-3.724-2.437-2.634-4.437L12 26Z"
      style={{
        fill: "#fb0",
        fillRule: "nonzero",
      }}
      transform="translate(-2.05)"
    />
    <path
      d="M13.5 42a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM23.5 43a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      style={{
        fill: "#ff8a00",
        fillRule: "nonzero",
      }}
      transform="translate(-2.05)"
    />
    <circle
      cx={18}
      cy={10}
      r={3}
      style={{
        fill: "#fb0",
      }}
      transform="translate(-2.05)"
    />
    <circle
      cx={13}
      cy={3}
      r={3}
      style={{
        fill: "#fb0",
      }}
      transform="translate(-2.05)"
    />
    <path
      d="M11 11a1 1 0 0 0 0 2h1v13h12V13h1a1 1 0 0 0 0-2H11Z"
      style={{
        fill: "#d9dbdd",
        fillRule: "nonzero",
      }}
      transform="translate(-2.05)"
    />
    <path
      d="M18 16h-6v2h6a1 1 0 0 0 0-2ZM18 21h-6v2h6a1 1 0 0 0 0-2Z"
      style={{
        fill: "#a8aab1",
        fillRule: "nonzero",
      }}
      transform="translate(-2.05)"
    />
  </svg>
);
export default CreateReportIcon;
