/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://uqokrp6hrnfyri5dwnqasgmfym.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-o4vlh6vhpjao7nn5hvzdwexddu",
    "aws_cognito_identity_pool_id": "eu-west-1:050a6fc4-3965-45ce-a03f-120d722df3eb",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ucf7VQvgN",
    "aws_user_pools_web_client_id": "6polvjbqmbnmnhjlqbtob27k4v",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "slalclientreporting-20230421130449-hostingbucket-devtwo",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d1qsktc0evndj2.cloudfront.net"
};


export default awsmobile;
