import {
  AMCSummary,
  AMCTotal,
  Label,
  SummaryWrapper,
  TotalAllocation,
  TotalAllocationSummary,
  Wrapper,
} from "./styles";
import AutoComplete from "../AutoComplete";
import { Fund } from "../../../types/types";
import SelectedFund from "./SelectedFund";
import { useFundList } from "./hooks";
import FundsHeader from "./FundsHeader";

type Option = {
  label: string;
  value: string;
  disabled: boolean;
};

interface FundListProps {
  funds: Fund[];
  loading?: boolean;
}

const FundList = ({ loading }: FundListProps) => {
  const {
    availableFunds,
    selectedFunds,
    totalAllocation,
    handleChange,
    updateFundAllocation,
    removeFund,
    amc,
  } = useFundList();

  const options = availableFunds.map(({ name, citicode, disabled }) => {
    return { label: name, value: citicode, disabled };
  });
  return (
    <Wrapper>
      <FundsHeader />
      {selectedFunds.map((fund, index) => (
        <SelectedFund
          key={fund.citicode}
          fund={fund}
          fundNumber={index + 1}
          onAllocationChange={updateFundAllocation}
          onRemoveFund={removeFund}
        />
      ))}
      {selectedFunds.length < 10 && (
        <AutoComplete
          options={options}
          onChange={({ value }: Option) => {
            handleChange(value);
          }}
          placeholder="Search for a fund..."
          name="fund"
          loading={loading}
        />
      )}
      <SummaryWrapper>
        <TotalAllocationSummary>
          <Label>Total allocation</Label>
        </TotalAllocationSummary>
        <TotalAllocation
          name="totalAllocation"
          suffix="%"
          disabled={true}
          maxLength={6}
          decimals={2}
          value={parseFloat(totalAllocation.toFixed(2)).toString()}
        />
      </SummaryWrapper>
      <SummaryWrapper>
        <AMCSummary>
          <Label>Blended AMC</Label>
          <AMCTotal>{amc ? `${amc.toFixed(2)}%` : ``}</AMCTotal>
        </AMCSummary>
      </SummaryWrapper>
    </Wrapper>
  );
};

export default FundList;
