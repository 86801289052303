import { useSelector } from "app/hooks";
import { useEffect } from "react";
import { logAnalyticsEvent } from "services/analytics";

export const useReportingPeriodScreen = () => {
  const { reportingPeriod } = useSelector((state) => state.investmentFunds);
  const { product } = useSelector((state) => state.investmentDetails);
  const continuteButtonDisabled = reportingPeriod === undefined;
  const continueUrl = continuteButtonDisabled ? "" : "/customisereport";
  const redirectHome = !product;

  useEffect(() => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 2",
      label: "Display",
    });
  }, []);

  const handleContinue = () => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 2",
      label: "Continue button",
    });
  };

  const handleBack = () => {
    logAnalyticsEvent({
      category: "Report details",
      action: "Page 2",
      label: "Back button",
    });
  };

  return {
    reportingPeriod,
    continueUrl,
    continuteButtonDisabled,
    redirectHome,
    handleContinue,
    handleBack,
  };
};
