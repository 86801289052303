import { Col } from "react-bootstrap";
import {
  ButtonContainer,
  ButtonsCol,
  LinkContainer,
  StyledCookieBanner,
} from "./styles";
import { P } from "components/ui/Typography";
import Button from "components/ui/Button";
import { useCookieBanner } from "./hooks";

export default function CookieBanner() {
  const { visible, handleDeclineAll, handleAcceptAll } = useCookieBanner();

  if (!visible) {
    return null;
  }
  return (
    <StyledCookieBanner>
      <Col xs={12} lg={6}>
        <P>
          We use strictly necessary cookies when you visit our website to give
          you the best experience possible and to keep things secure.
        </P>
        <P>
          We'd also like your consent to set other cookies to help us improve
          our website and to tailor the marketing you see.
        </P>
      </Col>
      <ButtonsCol
        xs={12}
        lg={6}
        className="my-auto justify-content-md-between justify-content-lg-end align-items-md-center align-items-lg-center"
      >
        <LinkContainer>
          <a
            role="button"
            target="_blank"
            rel="noreferrer"
            href="https://www.standardlife.ie/cookie-policy"
            title="link to cookie policy page"
          >
            Manage Settings
          </a>
        </LinkContainer>
        <ButtonContainer>
          <Button
            id="modal-button-decline-all"
            aria-label="Decline All cookies"
            onClick={handleDeclineAll}
          >
            Decline All
          </Button>
          <Button
            id="modal-button-accept-all"
            aria-label="Accept All cookies"
            onClick={handleAcceptAll}
          >
            Accept All
          </Button>
        </ButtonContainer>
      </ButtonsCol>
    </StyledCookieBanner>
  );
}
