import styled from "styled-components";
import { breakpointUp } from "helpers/breakpoints";

export const Wrapper = styled.div`
  display: none;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.bodyText};
  font-weight: bold;

  ${breakpointUp.sm`
        display: flex;
  `}

  ${breakpointUp.md`
        max-width: 370px;
    `}

  ${breakpointUp.lg`
        max-width: 450px;
    `}
`;

export const FundNameHeader = styled.p`
  display: flex;
  flex: 1 0 200px;
  margin-bottom: 0;

  ${breakpointUp.md`
        flex: 1 0 382px;
    `}

  ${breakpointUp.lg`
        flex: 1 0 474px;
    `}
`;

export const FundAMCHeader = styled.p`
  flex: 1 0 80px;
  margin-left: 8px;
  margin-bottom: 0;
`;

export const FundAllocationHeader = styled.p`
  flex: 1 0 120px;
  margin-left: 8px;
  margin-bottom: 0;
  margin-right: 32px;
`;
