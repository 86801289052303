import { Tooltip } from "react-tooltip";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: inline-block;
`;

export const SVG = styled.svg`
  display: block;
  color: ${({ theme }) => theme.colors.primaryAction};
  outline: none;
`;

export const TooltipContent = styled(Tooltip)`
  z-index: 10000;
  max-width: 300px;
  border-radius: 8px;
`;
