import FileIcon from "components/icons/FileIcon";
import { useDownloadReportButton } from "./hooks";
import {
  Backdrop,
  Button,
  ModalButton,
  Description,
  Dialog,
  Panel,
  PanelWrapper,
  Spinner,
  Title,
  DownloadLink,
  CloseModalButton,
  CloseModalIcon,
} from "./styles";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import "./ModalAnimations.css";

interface DownloadReportButtonProps {
  disabled?: boolean;
}

const DownloadReportButton = ({
  disabled,
  ...otherProps
}: DownloadReportButtonProps) => {
  const { handleCreateReport, isModalOpen, closeModal, reportUrl, viewReport } =
    useDownloadReportButton();

  return (
    <>
      <Button
        type="button"
        disabled={disabled || isModalOpen}
        onClick={handleCreateReport}
        {...otherProps}
      >
        Create report <FileIcon />
      </Button>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog open={isModalOpen} onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="backdrop-enter"
            enterFrom="backdrop-enter-from"
            enterTo="backdrop-enter-to"
            leave="backdrop-leave"
            leaveFrom="backdrop-leave-from"
            leaveTo="backdrop-leave-to"
          >
            <Backdrop />
          </Transition.Child>

          <PanelWrapper>
            <Transition.Child
              as={Fragment}
              enter="panel-enter"
              enterFrom="panel-enter-from"
              enterTo="panel-enter-to"
              leave="panel-leave"
              leaveFrom="panel-leave-from"
              leaveTo="panel-leave-to"
            >
              <Panel>
                {reportUrl ? (
                  <>
                    <CloseModalButton onClick={closeModal}>
                      <CloseModalIcon title="Close" />
                    </CloseModalButton>

                    <Title id="report-ready-title">Your report is ready!</Title>

                    <Description>
                      Click the button below to view your report
                    </Description>

                    <DownloadLink
                      href={reportUrl}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <ModalButton type="button" onClick={viewReport}>
                        View report <FileIcon />
                      </ModalButton>
                    </DownloadLink>
                  </>
                ) : (
                  <>
                    <CloseModalButton onClick={closeModal}>
                      <CloseModalIcon title="Close" />
                    </CloseModalButton>

                    <Title>Generating your report</Title>

                    <Description>This may take a few minutes</Description>

                    <Spinner />

                    <ModalButton type="button" secondary onClick={closeModal}>
                      Cancel
                    </ModalButton>
                  </>
                )}
              </Panel>
            </Transition.Child>
          </PanelWrapper>
        </Dialog>
      </Transition>
    </>
  );
};

export default DownloadReportButton;
